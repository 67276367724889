@if (isLoggingOut) {
  <app-loader
    [fullscreen]="true"
    [backdrop]="true"
  ></app-loader>
}
<nav class="navbar navbar-expand-lg navbar-light bg-white">
  <!-- Container wrapper -->
  <div class="container-fluid justify-content-between">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb ps-3">
        <li class="breadcrumb-item">
          <!-- <span>Dashboard</span> -->
        </li>
      </ol>
    </nav>

    <ul class="navbar-nav mb-2 mb-lg-0">
      <li mdbDropdown class="nav-item">
        <a
          class="nav-link dropdown-toggle d-flex align-items-center gap-2 py-0"
          type="button"
          id="dropdownMenuButton"
          aria-expanded="false"
          mdbDropdownToggle
          >
          <span class="avatar d-inline-block bg-grey-lighten-3 text-center me-2"
            ><i class="fa-user-alt fas"></i
          ></span>
          <span class="d-flex flex-column align-items-start">
            <p class="mb-0">{{ loggedInUser?.fullName }}</p>
            <span class="px-1 me-1 badge bg-info text-white">{{
              loggedInUser?.role
            }}</span>
          </span>
        </a>
        <div
          mdbDropdownMenu
          class="dropdown-menu dropdown-menu-end border-0"
          aria-labelledby="navbarDropdownMenuLink"
          >
          <a
            class="dropdown-item"
            role="button"
            (click)="showChangePasswordModal()"
            >Change Password</a
            >
            <a class="dropdown-item" role="button" [routerLink]="['/settings']">
              Settings</a
              >
              <a class="dropdown-item" (click)="logout()" role="button">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
