import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { DATA_TAPE_CONSTANTS } from '../constants/data-tape.constant';
import { YNPipe } from '../pipes';
import { ExcelHeader, ExcelTemplate } from '../constants/excel.constant';
import { PaceEquityUtils } from '../utils';
import { ExportUtils } from '../utils/export-util';
import moment from 'moment';
import { SERVICING_TYPES } from '../constants/servicing-types.constant';

// const fs = require('file-system');
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const EXCEL_EXTENSION = '.xlsx';
const Excel = require('exceljs');

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  private paceEquityBase64Image: any;

  constructor(
    private utils: PaceEquityUtils,
    // private datePipe: DatePipe, // private ynPipe: YNPipe
    private exportUtils: ExportUtils
  ) {
    utils.getPaceEquityBase64Image().then((base64Image) => {
      this.paceEquityBase64Image = base64Image;
    });
  }

  generateMultipleSheet(multipleReportData: any, callback?: any) {
    const workbook = new Excel.Workbook();
    [...multipleReportData].forEach((body, index) => {
      this.constructExcelReport(workbook, body, callback);

      // Generate Excel File with given name
    });
    callback && callback();
    const fileName =
      multipleReportData?.length > 1
        ? 'Global Portfolio Reporting'
        : `${multipleReportData[0]?.reportName}`;
    this.exportWorkbook(workbook, fileName);
  }

  getExcelFile(body: any, callback?: any) {
    // Create workbook and worksheet
    const workbook = new Excel.Workbook();

    this.constructExcelReport(workbook, body, callback);
    callback && callback();
    // Generate Excel File with given name
    const fileName = body.reportName ?? body.fileName;
    this.exportWorkbook(workbook, fileName + '_export_' + new Date().getTime());
  }

  constructExcelReport(workbook: any, body: any, callback?: any) {
    body.reportData.map((x: any, index: number) => {
      let reportName = body.reportName
        ? body.reportName
        : x?.projectName?.length >= 31
        ? x?.projectName.slice(0, 30) + index
        : x?.projectName.slice(0, 30);

      let worksheet = workbook.addWorksheet(reportName);
      const data =
        body.template === 'trialBalanceTemplate' ||
        body.template === 'openBalanceTemplate' ||
        body.template === 'dbrsTemplate' ||
        body.template === 'highRiskTemplate' ||
        body.template === 'assessmentBillingTemplate' ||
        body.template === 'remittanceReportTemplate' ||
        body.template === 'delinquencyReportTemplate' ||
        body.template === 'lenderDatabaseTemplate' ||
        body.template === 'trancheReportTemplate' ||
        body.template === 'prepaymentDetailTemplate' ||
        body.template === 'prepaymentSummaryTemplate' ||
        body.template === 'dataTapeTemplate' ||
        body.template === 'portfolioCfReportTemplate' ||
        body.template === 'dashboardViewTemplate'
          ? x
          : this.convertJsonToArray(x && x.schedule ? x.schedule : x);
      // Add Row and formatting
      const title =
        x.template === 'projectSummaryTemplate'
          ? 'Funding Summary for ' + body.projectName
          : body.reportTitle ?? x.projectName;

      const titleRow = worksheet.addRow([title]);
      titleRow.font = { bold: true, size: 16 };
      titleRow.height = body.portfolio ? 90 : 60;

      const subTitleRow = worksheet.addRow([
        // 'Execution time : ' + this.datePipe.transform(new Date(), 'medium'),
        'Execution time:' + moment().format('MMM D, YYYY, h:mm:ss a'),
      ]);
      if (body.template === 'trialBalanceTemplate') {
        subTitleRow.alignment = { horizontal: 'left' };
        subTitleRow.font = { size: 11 };
      } else {
        subTitleRow.alignment = { horizontal: 'right' };
        subTitleRow.font = { size: 9 };
      }

      // merge cells
      if (
        body.template === 'assetReportsTemplate' ||
        body.template === 'assessmentReportsTemplate' ||
        body.template === 'assessmentSummaryReportTemplate'
      ) {
        worksheet.mergeCells(`A${titleRow.number}:C${titleRow.number}`);
        worksheet.mergeCells(`A${subTitleRow.number}:C${subTitleRow.number}`);
      } else if (x.template === 'customerAmortizationTemplate') {
        worksheet.mergeCells(`A${titleRow.number}:B${titleRow.number}`);
        worksheet.mergeCells(`A${subTitleRow.number}:B${subTitleRow.number}`);
      } else if (x.template === 'projectSummaryTemplate') {
        titleRow.font = { bold: true, size: 16, color: { argb: 'FFFFFFFF' } };
        titleRow.getCell('A').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '80333f4f' },
        };
        titleRow.getCell('A').alignment = {
          horizontal: 'left',
          vertical: 'middle',
          wrapText: true,
        };

        worksheet.mergeCells(`A${titleRow.number}:E${titleRow.number}`);

        // const imageCell = titleRow.getCell('E');
        // imageCell.fill = {
        //   type: 'pattern',
        //   pattern: 'solid',
        //   fgColor: { argb: '80333f4f' },
        // };

        // // Add the image to the workbook
        // const imageId = workbook.addImage({
        //   base64: this.paceEquityBase64Image,
        //   extension: 'png',
        // });

        // // Add the image to the specific cell range
        // worksheet.addImage(imageId, 'E1:E1');

        worksheet.mergeCells(`A${subTitleRow.number}:E${subTitleRow.number}`);
      } else if (body.template == 'dashboardViewTemplate') {
        // worksheet.mergeCells(`A${titleRow.number}:F${titleRow.number}`);
        titleRow.height = 20;
        subTitleRow.alignment = { horizontal: 'left' };

        this.setCellProperties(worksheet.getCell('B1'), 'Group By', true);
        this.setCellProperties(
          worksheet.getCell('C1'),
          body.filter.groupBy,
          false
        );
        this.setCellProperties(worksheet.getCell('B2'), 'Search By', true);
        this.setCellProperties(
          worksheet.getCell('C2'),
          body.filter.searchBy,
          false
        );
        this.setCellProperties(worksheet.getCell('B3'), 'Filter By', true);

        let row = 3; // Starting row index
        let coloum = 3; // starting coloum index (C)

        for (const key in body.filter.filterBy) {
          if (body.filter.filterBy.hasOwnProperty(key)) {
            const cellKey = worksheet.getCell(row, coloum); // C1, D1, ...
            cellKey.font = { bold: true };

            const cellValue = worksheet.getCell(row + 1, coloum); // C2, D2, ...
            cellValue.alignment = { horizontal: 'left' };

            // cellKey.value = key;
            this.setCellProperties(cellKey, key, true);
            // cellValue.value = body.filter.filterBy[key];
            this.setCellProperties(cellValue, body.filter.filterBy[key], false);

            coloum++;
          }
        }
      } else {
        worksheet.mergeCells(`A${titleRow.number}:D${titleRow.number}`);
        worksheet.mergeCells(`A${subTitleRow.number}:D${subTitleRow.number}`);
      }

      // Blank Row
      let blankRow;
      if (x.template != 'projectSummaryTemplate') blankRow = worksheet.addRow([]);

      //If hide report execution time
      if (body.hideReportExecutionTime) {
        worksheet.spliceRows(blankRow.number - 1, 1);
      }

      // Add Header Row
      let headerRow;
      let topHeaderRow;
      let portfolioHeaderRow;
      if (
        body.template == 'portfolioCfReportTemplate' ||
        body.template == 'peqCfReportTemplate'
      ) {
        headerRow = worksheet.getRow(blankRow.number + 3);
        topHeaderRow = worksheet.getRow(blankRow.number + 2);
        portfolioHeaderRow = worksheet.getRow(blankRow.number + 1);
      } else if (x.template == 'projectSummaryTemplate') {
        headerRow = worksheet.getRow(3);
      } else {
        headerRow = worksheet.getRow(blankRow.number + 1);
      }
      let reportHeaderKeys;
      if (body.template == 'portfolioCfReportTemplate') {
        reportHeaderKeys = this.utils.getPortfolioCfHeaderKeys(
          body.selectedProjectList
        );
        const reportHeaderValues = this.utils.getPortfolioCfHeaderValues(
          body.selectedProjectList
        );
        const reportTopHeaderValues = this.utils.getPortfolioCfTopHeaderValues(
          body.selectedProjectList,
          'projectName'
        );
        // const reportTopHeaderPortfolioValues = this.utils.getPortfolioCfTopHeaderValues(body.selectedProjectList, 'portfolioName')
        topHeaderRow.values = reportTopHeaderValues;
        portfolioHeaderRow.values = [
          'Note: ' +
            body.selectedPortfolio +
            ' include Post Closing and Servicing only',
        ];
        topHeaderRow.eachCell((cell: any, number: number) => {
          cell.font = { size: 12, bold: true };
        });
        worksheet.columns = reportHeaderKeys;
        worksheet.mergeCells(
          `A${portfolioHeaderRow.number}:D${portfolioHeaderRow.number}`
        );
        for (
          let index = 1;
          index <= reportHeaderKeys.length;
          index = index + 3
        ) {
          worksheet.mergeCells(
            `${this.utils.getExcelColumnName(index + 1)}${
              topHeaderRow.number
            }:${this.utils.getExcelColumnName(index + 3)}${topHeaderRow.number}`
          );
        }
        headerRow.values = reportHeaderValues;
        headerRow.alignment = { horizontal: 'center' };
      } else if (body.template == 'peqCfReportTemplate') {
        reportHeaderKeys = this.utils.getPeqCfHeaderKeys(
          body.selectedProjectList
        );
        const reportHeaderValues = this.utils.getPeqCfHeaderValues(
          body.selectedProjectList
        );
        const reportTopHeaderValues = this.utils.getPeqCfTopHeaderValues(
          body.selectedProjectList,
          'projectName'
        );
        // const reportTopHeaderPortfolioValues = this.utils.getPeqCfTopHeaderValues(body.selectedProjectList, 'portfolioName')
        topHeaderRow.values = reportTopHeaderValues;
        portfolioHeaderRow.values = [
          'Note: ' +
            body.selectedPortfolio +
            ' include Post Closing and Servicing only',
        ];
        topHeaderRow.eachCell((cell: any, number: number) => {
          cell.font = { size: 12, bold: true };
        });
        worksheet.columns = reportHeaderKeys;
        worksheet.mergeCells(
          `A${portfolioHeaderRow.number}:D${portfolioHeaderRow.number}`
        );
        for (
          let index = 1;
          index <= reportHeaderKeys.length;
          index = index + 4
        ) {
          index == 1
            ? worksheet.mergeCells(
                `${this.utils.getExcelColumnName(index + 1)}${
                  topHeaderRow.number
                }:${this.utils.getExcelColumnName(index + 3)}${
                  topHeaderRow.number
                }`
              )
            : worksheet.mergeCells(
                `${this.utils.getExcelColumnName(index)}${
                  topHeaderRow.number
                }:${this.utils.getExcelColumnName(index + 3)}${
                  topHeaderRow.number
                }`
              );
        }
        headerRow.values = reportHeaderValues;
        headerRow.alignment = { horizontal: 'center' };
      } else if (body.template == 'dashboardViewTemplate') {
        let headerElements: any = [];
        body.reportHeader.map((x: any) => {
          headerElements.push(x.value);
        });
        headerRow.values = headerElements;
        worksheet.columns = body.reportHeader;
      } else {
        const reportHeader = body.reportHeader
          ? body.reportHeader
          : body.template === 'amortizationScheduleTemplate'
          ? this.utils.getAmortizationScheduleReportHeaders(x.portfolioCode)
          : body.template === 'clientAssessmentScheduleTemplate'
          ? ExcelHeader.CLIENT_ASSESSMENT_SCHEDULE
          : x.template == 'projectSummaryTemplate' ||
            x.template == 'assessmentReportsTemplate'
          ? this.utils.getProjectSummaryHeaders()
          : x.template == 'customerAmortizationTemplate'
          ? this.utils.getCustomerAmortizationHeaders()
          : this.utils.getAssetReportHeaders(x.portfolioCode);
        headerRow.values = Array.from(reportHeader.values());
        worksheet.columns = Array.from(reportHeader.keys()).map((y) => {
          return {
            key: y,
          };
        });
      }

      // Cell Style : Fill and Border
      headerRow.eachCell((cell: any, number: number) => {
        cell.font = { bold: true };
      });
      if (body.template === 'capITemplate') {
        worksheet = this.convertToCapITemplate(worksheet, data, headerRow);
      } else if (body.template === 'amortizationScheduleTemplate') {
        worksheet = this.convertToAmortizationScheduleTemplate(
          worksheet,
          data,
          headerRow
        );
      } else if (
        body.template === 'assetReportsTemplate' ||
        body.template === 'assessmentReportsTemplate'
      ) {
        worksheet = this.convertToAssetReportTemplate(
          worksheet,
          data,
          x.portfolioCode
        );
      } else if (body.template === 'dataTapeTemplate') {
        headerRow.alignment = { horizontal: 'center' };
        worksheet = this.convertToDataTapeTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template === 'trialBalanceTemplate') {
        worksheet = this.convertToTrialBalanceTemplate(
          worksheet,
          data,
          headerRow
        );
      } else if (body.template === 'remittanceReportTemplate') {
        worksheet = this.convertToRemittanceTemplate(
          worksheet,
          x ? x : data,
          headerRow,
          body.template
        );
      } else if (body.template === 'delinquencyReportTemplate') {
        worksheet = this.convertToDelinquencyTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (x.template == 'projectSummaryTemplate') {
        worksheet = this.convertToProjectSummaryTemplate(
          worksheet,
          data,
          headerRow,
          workbook
        );
      } else if (x.template == 'customerAmortizationTemplate') {
        worksheet = this.convertToCustomerAmoTemplate(
          worksheet,
          data,
          headerRow
        );
      } else if (body.template == 'portfolioCfReportTemplate') {
        worksheet = this.convertToPortfolioCfReportTemplate(
          worksheet,
          data,
          headerRow,
          topHeaderRow,
          portfolioHeaderRow,
          reportHeaderKeys,
          body.selectedProjectList
        );
      } else if (body.template == 'peqCfReportTemplate') {
        worksheet = this.convertToPeqCfReportTemplate(
          worksheet,
          data,
          headerRow,
          topHeaderRow,
          portfolioHeaderRow,
          reportHeaderKeys,
          body.selectedProjectList
        );
      } else if (x.template == 'insuranceCertificationTemplate') {
        worksheet = this.convertToInsuranceCertificationTemplate(
          worksheet,
          data,
          headerRow,
          x.template
        );
      } else if (body.template == 'dashboardViewTemplate') {
        worksheet = this.convertToDashboardViewTemplate(
          worksheet,
          data,
          headerRow,
          body.reportHeader,
          body.totalSum,
          body.grouping
        );
      } else if (x.template == 'disbursementTemplate') {
        worksheet = this.convertToDisbursementTemplate(
          worksheet,
          data,
          headerRow,
          x.template
        );
      } else if (body.template == 'openBalanceTemplate') {
        worksheet = this.convertToOpenBalanceTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template == 'dbrsTemplate') {
        worksheet = this.convertToDBRSTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template == 'highRiskTemplate') {
        worksheet = this.convertToHighRiskTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template == 'assessmentBillingTemplate') {
        worksheet = this.convertToAssessmentBillingTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template == 'lenderDatabaseTemplate') {
        worksheet = this.convertToLenderDatabaseTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template == 'trancheReportTemplate') {
        worksheet = this.convertToTrancheReportTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template == 'prepaymentDetailTemplate') {
        worksheet = this.convertToPrepaymentDetailTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else if (body.template == 'prepaymentSummaryTemplate') {
        worksheet = this.convertToPrepaymentSummaryTemplate(
          worksheet,
          data,
          headerRow,
          body.template
        );
      } else {
        worksheet.addRows(data); // add data all at once
      }

      titleRow.alignment = {
        vertical: 'middle',
        horizontal: 'left',
        wrapText: true,
      };
    });
    callback && callback();
  }

  exportWorkbook(workbook: any, fileName: any) {
    workbook.xlsx
      .writeBuffer()
      .then((writeData: any) =>
        FileSaver.saveAs(
          new Blob([writeData], { type: EXCEL_TYPE }),
          fileName + EXCEL_EXTENSION
        )
      );
  }
  convertJsonToArray(json: any, template?: any) {
    json = JSON.parse(JSON.stringify(json));
    const arrayData: any = [];
    json.map((data: any) => {
      delete data.portfolio;
      const objKeys = Object.keys(data); // all keys of the object

      objKeys.map((key) => {
        if (Array.isArray(data[key])) {
          if (template == 'dataTapeTemplate') {
            let commaSepValue = '';
            data[key].map((y: any) => {
              if (key == 'sweepAmounts') {
                commaSepValue = commaSepValue + '$' + y.value + ', ';
              } else {
                commaSepValue = commaSepValue + y.value + ', ';
              }
            });
            data[key] = commaSepValue.substring(0, commaSepValue.length - 2);
            // data[key] = data[key].value.join(', ');
          } else if (template == 'dataTapeDocumentSourceTemplate') {
            let commaSepValue = '';
            data[key].map((y: any) => {
              commaSepValue = y.documentAlias
                ? commaSepValue + y.documentAlias + ', '
                : commaSepValue;
            });
            data[key] = commaSepValue
              ? commaSepValue.substring(0, commaSepValue.length - 2)
              : commaSepValue;
          } else {
            data[key] = data[key].join(', ');
          }
        }
        if (this.utils.isObject(data[key])) {
          if (template == 'dataTapeTemplate') {
            data[key] = data[key].value;
          } else if (template == 'dataTapeDocumentSourceTemplate') {
            if (key == 'assetNumber' || key == 'projectName') {
              data[key] = data[key].value;
            } else {
              data[key] = data[key].documentAlias;
            }
          }
        }
        if (data[key] == null) {
          if (template == 'dataTapeTemplate') {
            data[key] = 'N/A';
          } else {
            data[key] = '';
          }
        }
      });
      arrayData.push(data);
    });
    return arrayData;
  }

  convertToProjectSummaryTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    workbook?: any
  ) {
    let row;

    // const row2 = worksheet.getRow(2);
    // row2.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFFFFFFF' }, // Set the color to white (hex code: FFFFFF)
    // };

    // const row3 = worksheet.getRow(3);
    // row3.fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFFFFFFF' }, // Set the color to white (hex code: FFFFFF)
    // };
    // Project Summary Data
    let backupData = [...data];
    backupData.splice(4);
    for (const item of backupData) {
      const {
        input1 = null,
        value1 = null,
        input2 = null,
        value2 = null,
      } = item;
      if (input1 || value1 || input2 || value2) {
        row = worksheet.addRow([
          item.input1,
          item.value1,
          ' ',
          item.input2,
          item.value2,
        ]);
        row.height = 25;
      }

      // Bold text for Column A and Column D
      if (row) {
        const fillParam = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' }, // Set the color to white (hex code: FFFFFF)
        };
        const columnA = worksheet.getCell(`A${row.number}`);
        columnA.font = { bold: true };
        columnA.fill = fillParam;
        columnA.alignment = {
          vertical: 'middle',
          horizontal: 'left',
          wrapText: true,
        };

        const columnB = worksheet.getCell(`B${row.number}`);
        columnB.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        };
        if (row.number == 4 || row.number == 6) {
          columnB.numFmt = ExcelTemplate.CURRENCY;
        }
        if (row.number == 7 && typeof value1 == 'number') {
          columnB.numFmt = ExcelTemplate.RATE;
        }

        columnB.fill = fillParam;

        worksheet.getCell(`C${row.number}`).fill = fillParam;

        const columnD = worksheet.getCell(`D${row.number}`);
        columnD.font = { bold: true };
        columnD.alignment = {
          vertical: 'middle',
          horizontal: 'left',
          wrapText: true,
        };
        columnD.fill = fillParam;

        const columnE = worksheet.getCell(`E${row.number}`);
        columnE.alignment = {
          vertical: 'middle',
          horizontal: 'right',
          wrapText: true,
        };
        columnE.fill = fillParam;
      }
    }
    // End of Project Summary Data
    // Add row for use of fund
    const useOfFundRow = worksheet.addRow(['Use of Fund']);
    useOfFundRow.height = 20;
    useOfFundRow.getCell('A').alignment = {
      horizontal: 'center',
      vertical: 'middle',
      wrapText: true,
    };
    useOfFundRow.font = { bold: true, size: 14, color: { argb: 'FFFFFFFF' } };
    useOfFundRow.getCell('A').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '333f4f' },
    };
    worksheet.mergeCells(`A${useOfFundRow.number}:E${useOfFundRow.number}`);
    // end of use of fund

    const newData = [...data].filter(
      (x) => x.input1 && !x.input2 && x.value1 != null && !x.value2
    );
    //Removed Interest Rate
    newData.splice(0, 1);
    //Removed Asset Rate & Benchmark Rate to prevent from writing in 'Use of Funds'.
    newData.splice(7);
    for (let i = 0; i < newData.length; i++) {
      const item = newData[i];
      if (item.input1 && item.value1 != null) {
        row = worksheet.addRow([item.input1, ' ', ' ', ' ', item.value1]);
      }
      if (row) {
        // Set row background color alternatively
        const isEvenRow = (i + 1) % 2 === 0;
        const backgroundColor = isEvenRow ? 'FFFFFFFF' : 'd9e1f2';

        const fillParam = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: backgroundColor },
        };

        const columnA = row.getCell(`A`);
        columnA.alignment = { vertical: 'middle', horizontal: 'left' };
        columnA.fill = fillParam;
        worksheet.mergeCells(`A${row.number}:D${row.number}`);

        const columnE = worksheet.getCell(`E${row.number}`);
        columnE.numFmt = ExcelTemplate.CURRENCY;
        columnE.alignment = { vertical: 'middle', horizontal: 'right' };
        columnE.fill = fillParam;
      }
    }

    worksheet.getRow(15).eachCell((cell: any) => {
      cell.font = { bold: true };
    });

    worksheet.getColumn('A').width = 22;
    worksheet.getColumn('B').width = 40;
    worksheet.getColumn('C').width = 5;
    worksheet.getColumn('D').width = 25;
    worksheet.getColumn('E').width = 30;

    const benchmarkRate = data[11].value1;
    const assetRate = data[12].value1;
    if (benchmarkRate && benchmarkRate != 'N/A') {
      const lastRow = worksheet.addRow([
        `Note: Illustration based on rate of ${assetRate}% with underlying benchmark 10 Year UST of ${benchmarkRate}%`,
      ]);
      lastRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFFFF' },
      };
      lastRow.alignment = {
        horizontal: 'left',
        vertical: 'middle',
        wrapText: true,
      };
      lastRow.height = 30;
      worksheet.mergeCells(`A${lastRow.number}:E${lastRow.number}`);
    }

    const projectSummarySheet = workbook.getWorksheet('Project Summary Report'); // Replace 'Sheet1' with the actual sheet name

    // Crop the worksheet to display columns A to F
    projectSummarySheet.views = [
      {
        state: 'frozen',
        xSplit: 5, // Index of the last visible column (F), zero-based
      },
    ];
    const row3 = worksheet.getRow(3);
    row3.hidden = true;
    return worksheet;
  }

  convertToInsuranceCertificationTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    template: any
  ) {
    headerRow.fill = ExcelTemplate.GREY_FILL;

    data.map((r, i) => {
      const row = worksheet.addRow(r);
      row.getCell('A').alignment = { wrapText: true };
      row.getCell('B').alignment = { wrapText: true };
      row.getCell('C').alignment = { wrapText: true };
      row.getCell('D').alignment = { wrapText: true };
      row.getCell('E').alignment = { wrapText: true };
      row.getCell('A').value =
        r.dueDate == 'N/A' ? r.dueDate : moment(r.dueDate).format('YYYY/MM/DD');

      this.generateAutoWidth(worksheet);
    });
    headerRow.alignment = { wrapText: true };

    const dateColumn = ['A'];
    const otherColumn = ['B', 'C', 'D', 'E'];
    dateColumn.map((col) => {
      worksheet.getColumn(col).alignment = {
        horizontal: 'center',
        vertical: 'top',
      };
    });
    otherColumn.map((col) => {
      worksheet.getColumn(col).alignment = {
        horizontal: 'left',
        vertical: 'top',
      };
    });
    worksheet.getColumn('C').width = 80;
    worksheet.getColumn('C').alignment = { wrapText: true };

    worksheet.getColumn('D').width = 80;
    worksheet.getColumn('D').alignment = { wrapText: true };
  }
  convertToDisbursementTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    template: any
  ) {
    headerRow.fill = ExcelTemplate.GREY_FILL;

    data.map((r, i) => {
      const row = worksheet.addRow(r);
      row.getCell('A').alignment = { wrapText: true };
      row.getCell('B').alignment = { wrapText: true };
      row.getCell('C').alignment = { wrapText: true };
      row.getCell('D').alignment = { wrapText: true };
      row.getCell('E').alignment = { wrapText: true };
      row.getCell('F').alignment = { wrapText: true };
      row.getCell('G').alignment = { wrapText: true };
      row.getCell('H').alignment = { wrapText: true };

      // row.getCell('B').value = r.drawDate == "N/A" ? r.drawDate : moment(r.drawDate).format('YYYY/MM/DD');
      if (i === data.length - 1) {
        const y = Object.create({});
        y.drawNo = 'Total';
        y.grossAmountRequested = r.grossAmountTotal
          ? this.exportUtils.formatNumberToFixed(r.grossAmountTotal)
          : 0;

        const totalRow = worksheet.addRow(y);
        worksheet.mergeCells(`A${totalRow.number}:C${totalRow.number}`);

        totalRow.font = { size: 11, bold: true };
      }
      this.generateAutoWidth(worksheet);
    });
    headerRow.alignment = { wrapText: true };

    const dateColumn = ['B', 'C'];
    const currencyColumn = ['D', 'E', 'F'];
    const otherColumn = ['A', 'G', 'H'];

    dateColumn.map((col) => {
      worksheet.getColumn(col).alignment = {
        horizontal: 'center',
        vertical: 'top',
      };
    });
    currencyColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    otherColumn.map((col) => {
      worksheet.getColumn(col).alignment = {
        horizontal: 'left',
        vertical: 'top',
      };
    });
  }

  convertToOpenBalanceTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.getRow(1).height = 100;
    headerRow.fill = ExcelTemplate.GREY_FILL;
    headerRow.alignment = { wrapText: true };
    data
      .filter((x) => x.projects)
      .map((x, i) => {
        x.projectName = x.portfolioName;
        const row = worksheet.addRow(x);
        worksheet.mergeCells(`B${row.number}:Q${row.number}`);
        row.font = { size: 13, bold: true };
        x.projects.map((z: any) => {
          z.bankName = z.bankName ? z.bankName : 'N/A';
          z.directCost =
            z.draws && z.draws.directCost
              ? this.exportUtils.formatNumberToFixed(z.draws.directCost)
              : 0;
          z.disbursedAmount =
            z.draws && z.draws.disbursedAmount
              ? this.exportUtils.formatNumberToFixed(z.draws.disbursedAmount)
              : 0;
          z.openBalance =
            z.draws && z.draws.disbursementBalance
              ? this.exportUtils.formatNumberToFixed(
                  z.draws.disbursementBalance
                )
              : 0;
          z.percentDisbursed =
            z.draws && z.draws.percentDisbursed
              ? this.exportUtils.formatNumberToFixed(z.draws.percentDisbursed)
              : 0;
          z.paymentDate =
            z.draws && z.draws.paymentDate ? z.draws.paymentDate : 'N/A';
          const row = worksheet.addRow(z);

          row.getCell('A').alignment = { wrapText: true };
          row.getCell('B').alignment = { wrapText: true };
          row.getCell('C').alignment = { wrapText: true };
          row.getCell('D').alignment = { wrapText: true };
          row.getCell('E').alignment = { wrapText: true };
          row.getCell('F').alignment = { wrapText: true };

          // row.getCell('B').value = r.drawDate == "N/A" ? r.drawDate : moment(r.drawDate).format('YYYY/MM/DD');
          if (z.projectName.toLowerCase() == 'portfolio total') {
            row.font = { size: 11, bold: true };
          }
          this.generateAutoWidth(worksheet);
        });
        //Row for Total Disbursements To Date, Total Open Balance and Total Percent Disbursed
        const y = Object.create({});
        y.projectName = 'Portfolio Total';
        y.directCost = x.totalDirectCost
          ? this.exportUtils.formatNumberToFixed(x.totalDirectCost)
          : 0;
        y.disbursedAmount = x.totalDisbursementsToDate
          ? this.exportUtils.formatNumberToFixed(x.totalDisbursementsToDate)
          : 0;
        y.openBalance = x.totalOpenBalance
          ? this.exportUtils.formatNumberToFixed(x.totalOpenBalance)
          : 0;
        y.percentDisbursed = x.totalPercentageDisbursed
          ? this.exportUtils.formatNumberToFixed(x.totalPercentageDisbursed)
          : 0;

        const totalRow = worksheet.addRow(y);

        totalRow.font = { size: 10, bold: true };
        worksheet.addRow();
      });
    data
      .filter((x) => !x.projects)
      .map((x) => {
        const y = Object.create({});
        y.projectName = 'Total';
        y.directCost = x.totalDirectCost
          ? this.exportUtils.formatNumberToFixed(x.totalDirectCost)
          : 0;
        y.disbursedAmount = x.totalDisbursementsToDate
          ? this.exportUtils.formatNumberToFixed(x.totalDisbursementsToDate)
          : 0;
        y.openBalance = x.totalOpenBalance
          ? this.exportUtils.formatNumberToFixed(x.totalOpenBalance)
          : 0;
        y.percentDisbursed = x.totalPercentageDisbursed
          ? this.exportUtils.formatNumberToFixed(x.totalPercentageDisbursed)
          : 0;

        const totalRow = worksheet.addRow(y);

        totalRow.font = { size: 10, bold: true };
        worksheet.addRow();
      });

    const dateColumn = ['G'];
    const currencyColumn = ['C', 'D', 'E'];
    const rateColumn = ['F'];
    const otherColumn = ['A', 'B'];

    dateColumn.map((col) => {
      worksheet.getColumn(col).alignment = {
        horizontal: 'center',
        vertical: 'top',
      };
    });
    currencyColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    rateColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.RATE;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    otherColumn.map((col) => {
      worksheet.getColumn(col).alignment = {
        horizontal: 'left',
        vertical: 'top',
      };
    });

    this.generateAutoWidth(worksheet);
    return worksheet;
  }
  convertToCustomerAmoTemplate(worksheet: any, data: any[], headerRow: any) {
    headerRow.fill = ExcelTemplate.GREY_FILL;
    data.map((r, i) => {
      r = this.roundUpOldMethod(r);

      const row = worksheet.addRow(r);

      row.getCell('A').font = { bold: true };
      row.getCell('A').alignment = { wrapText: true };
      row.getCell('B').alignment = { horizontal: 'right', wrapText: true };
      if (row.number == 5 || row.number == 22) {
        row.getCell('B').alignment = { horizontal: 'left', wrapText: true };
      }
      if (
        row.number == 6 ||
        row.number == 7 ||
        row.number == 8 ||
        row.number == 9 ||
        row.number == 10 ||
        row.number == 20
      ) {
        row.getCell('B').numFmt = ExcelTemplate.CURRENCY;
      }
      if (row.number == 14) {
        row.getCell('B').numFmt = ExcelTemplate.RATE;
      }
      if (row.number == 23) {
        worksheet.mergeCells(`A${row.number}:B${row.number}`);
      }
      this.generateAutoWidth(worksheet);
      worksheet.getColumn('A').width = 40;
      worksheet.getColumn('B').width = 30;
      worksheet.getColumn('C').width = 2;
      worksheet.views = [
        { state: 'frozen', xSplit: 2, ySplit: 0 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
      ];
    });
    const currencyColumn = ['G', 'H', 'I', 'J', 'K', 'L', 'M'];
    const dateColumn = ['E', 'N'];
    const otherColumn = ['D', 'F'];
    currencyColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    dateColumn.map((col) => {
      worksheet.getColumn(col).alignment = { horizontal: 'center' };
    });
    otherColumn.map((col) => {
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
  }

  convertToCapITemplate(worksheet: any, data: any[], headerRow: any) {
    worksheet.getRow(1).height = 180;
    headerRow.height = 90;
    headerRow.alignment = { wrapText: true };
    const capIStartCell = headerRow.getCell('N');
    const accuredStartCell = headerRow.getCell('T');
    let capIEndCell;
    let accuredEndCell;
    headerRow.getCell('L').font = ExcelTemplate.BLUE_FONT;
    headerRow.getCell('N').font = ExcelTemplate.BLUE_FONT;
    headerRow.getCell('O').font = ExcelTemplate.BLUE_FONT;
    headerRow.getCell('P').font = ExcelTemplate.RED_FONT;
    data.map((x, i) => {
      const row = worksheet.addRow(x);
      const funded = row.getCell('K');
      const distributed = row.getCell('L');
      const reserved = row.getCell('S');
      const netAccured = row.getCell('T');
      const servicingAccured = row.getCell('U');
      const paymentDue = row.getCell('P');
      const capIStart = row.getCell('N');
      const nextCapI = row.getCell('O');
      funded.fill = ExcelTemplate.GREY_COLUMN;
      distributed.fill = ExcelTemplate.GREY_COLUMN;
      reserved.fill = ExcelTemplate.GREY_COLUMN;
      netAccured.fill = ExcelTemplate.GREEN_COLUMN;
      servicingAccured.fill = ExcelTemplate.GREEN_COLUMN;
      distributed.font = ExcelTemplate.BLUE_FONT;
      paymentDue.font = ExcelTemplate.RED_FONT;
      capIStart.font = ExcelTemplate.BLUE_FONT;
      nextCapI.font = ExcelTemplate.BLUE_FONT;
      if (i == data.length - 1) {
        capIEndCell = row.getCell('R');
        accuredEndCell = row.getCell('U');
      }
    });
    worksheet = this.createOuterBorderMultipleCells(
      capIStartCell,
      capIEndCell,
      worksheet
    ); // send first row first cell and last row last cell
    worksheet = this.createOuterBorderMultipleCells(
      accuredStartCell,
      accuredEndCell,
      worksheet
    );
    const rateColumns = ['E', 'F', 'G'];
    const currencyColumn = ['H', 'K', 'L', 'Q', 'R', 'S', 'T', 'U'];
    const dateColumn = ['I', 'J', 'N', 'O', 'P'];

    rateColumns.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.RATE;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    currencyColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    dateColumn.map((col) => {
      worksheet.getColumn(col).alignment = { horizontal: 'center' };
    });
    this.generateAutoWidth(worksheet);
    return worksheet;
  }

  convertToTrialBalanceTemplate(worksheet: any, data: any[], headerRow: any) {
    data.map((x, i) => {
      x.projectName = x.portfolioName;
      const row = worksheet.addRow(x);
      worksheet.mergeCells(`A${row.number}:I${row.number}`);
      row.font = { size: 13, bold: true };
      x.payments.map((y: any) => {
        const row = worksheet.addRow(y);
      });
      //Row for Total Original Amount and Borrower balance
      const y = Object.create({});
      y.projectName = 'Portfolio Total';
      y.originalAmount = x.totalOriginalAmt
        ? this.exportUtils.formatNumberToFixed(x.totalOriginalAmt)
        : 0;
      y.borrowerBalance = x.totalBorrowerBalance
        ? this.exportUtils.formatNumberToFixed(x.totalBorrowerBalance)
        : 0;
      const totalRow = worksheet.addRow(y);
      totalRow.font = { size: 10, bold: true };
      worksheet.addRow();
    });

    // TO ADD GROSS TOTAL
    const gt = Object.create({});
    gt.projectName = 'Total';
    gt.originalAmount = data.reduce(
      (accumulator, current) => accumulator + current.totalOriginalAmt,
      0
    );
    gt.borrowerBalance = data.reduce(
      (accumulator, current) => accumulator + current.totalBorrowerBalance,
      0
    );
    gt.originalAmount = gt.originalAmount
      ? this.exportUtils.formatNumberToFixed(gt.originalAmount)
      : 0;
    gt.borrowerBalance = gt.borrowerBalance
      ? this.exportUtils.formatNumberToFixed(gt.borrowerBalance)
      : 0;
    const totalRow = worksheet.addRow(gt);
    totalRow.font = { size: 10, bold: true };
    worksheet.addRow();

    const rateColumns = ['H', 'I'];
    const currencyColumn = ['E', 'G'];
    const dateColumn = ['D', 'F', 'J', 'K', 'L'];
    const centerColumn = ['A', 'B', 'C'];

    rateColumns.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.RATE;
      worksheet.getColumn(col).width = 10;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    currencyColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
      worksheet.getColumn(col).width = 15;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    dateColumn.map((col) => {
      worksheet.getColumn(col).alignment = { horizontal: 'center' };
    });

    centerColumn.map((col) => {
      worksheet.getColumn(col).alignment = { horizontal: 'left' };
    });

    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    this.generateAutoWidth(worksheet);
    return worksheet;
  }

  convertToRemittanceTemplate(
    worksheet: any,
    remittanceData: any,
    headerRow: any,
    bodyTemplate: any,
    body?: any
  ) {
    worksheet.getRow(1).height = 100;
    const data = remittanceData?.reportList
      ? remittanceData.reportList
      : remittanceData;
    data
      .filter((x: any) => x.projects)
      .map((x: any, i: any) => {
        x.name = x.portfolioName;
        const row = worksheet.addRow(x);
        worksheet.mergeCells(`B${row.number}:Q${row.number}`);
        row.font = { size: 11, bold: true };
        x.projects.map((y: any) => {
          const dataList = y.remittances;
          dataList.map((z: any) => {
            z.name = y.name;
            z.expectedPaymentDate =
              z.expected && z.expected.paymentDate
                ? z.expected.paymentDate
                : 'N/A';
            z.receivedPaymentDate =
              z.received && z.received.paymentDate
                ? z.received.paymentDate
                : z.daysOverdue > 0
                ? 'Overdue'
                : '-';
            z.daysOverdue = z && z.daysOverdue > 0 ? z.daysOverdue : '-';
            z.receivedPrincipalAmount =
              z.received && z.received.principalAmount
                ? this.exportUtils.formatNumberToFixed(
                    z.received.principalAmount
                  )
                : 0;
            z.receivedPehInterest = z.received?.pehInterest
              ? this.exportUtils.formatNumberToFixed(z.received.pehInterest)
              : 0;
            z.receivedPeServicing = z.received?.peServicing
              ? this.exportUtils.formatNumberToFixed(z.received.peServicing)
              : 0;
            z.receivedAdminInterest = z.received?.adminInterest
              ? this.exportUtils.formatNumberToFixed(z.received.adminInterest)
              : 0;
            z.receivedInterestAmount =
              z.received && z.received.interestAmount
                ? this.exportUtils.formatNumberToFixed(
                    z.received.interestAmount
                  )
                : 0;
            z.receivedProgramAdminFee =
              z.received && z.received.programAdminFee
                ? this.exportUtils.formatNumberToFixed(
                    z.received.programAdminFee
                  )
                : 0;
            z.receivedPeqAdminFee =
              z.received && z.received.peqAdminFee
                ? this.exportUtils.formatNumberToFixed(z.received.peqAdminFee)
                : 0;
            z.receivedLateFee =
              z.received && z.received.lateFee
                ? this.exportUtils.formatNumberToFixed(z.received.lateFee)
                : 0;
            z.receivedTotalAmount =
              z.received && z.received.totalAmount
                ? this.exportUtils.formatNumberToFixed(z.received.totalAmount)
                : 0;
            z.varianceTotalAmount =
              z.received && z.received.variance != null
                ? this.exportUtils.formatNumberToFixed(z.received.variance)
                : '-';
            z.endingBalance =
              z && z.endingBalance
                ? this.exportUtils.formatNumberToFixed(z.endingBalance)
                : 0;
            z.notes = z.received && z.received.notes ? z.received.notes : 'N/A';
            const row = worksheet.addRow(z);

            row.alignment = {
              vertical: 'middle',
              horizontal: 'center',
              wrapText: true,
            };

            if (z.name.toLowerCase() == 'portfolio total') {
              row.font = { size: 11, bold: true };
            }
          });
        });
        //#region Portfolio Total
        //Row for Total for all amounts
        const y = Object.create({});
        y.name = 'Remittance Total';
        y.receivedPrincipalAmount = x.remittanceTotal?.principalAmount
          ? this.exportUtils.formatNumberToFixed(
              x.remittanceTotal?.principalAmount
            )
          : 0;
        y.endingBalance = x.remittanceTotal?.endingBalance
          ? this.exportUtils.formatNumberToFixed(
              x.remittanceTotal?.endingBalance
            )
          : 0;

        const totalRow = worksheet.addRow(y);
        worksheet.mergeCells(`A${totalRow.number}:D${totalRow.number}`);
        totalRow.font = { size: 11, bold: true };
        totalRow.fill = ExcelTemplate.REMITANCE_ROW;

        const portfolioTotal = Object.create({});
        portfolioTotal.name = 'Portfolio Total';
        portfolioTotal.receivedPrincipalAmount = x.portfolioTotal
          ?.totalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(
              x.portfolioTotal?.totalAmountFinanced
            )
          : 0;
        portfolioTotal.receivedInterestAmount = x.portfolioTotal?.interestAmount
          ? this.exportUtils.formatNumberToFixed(
              x.portfolioTotal?.interestAmount
            )
          : 0;

        portfolioTotal.receivedPehInterest = x.portfolioTotal?.pehInterest
          ? this.exportUtils.formatNumberToFixed(x.portfolioTotal?.pehInterest)
          : 0;
        portfolioTotal.receivedPeServicing = x.portfolioTotal?.peServicing
          ? this.exportUtils.formatNumberToFixed(x.portfolioTotal?.peServicing)
          : 0;
        portfolioTotal.receivedAdminInterest = x.portfolioTotal?.adminInterest
          ? this.exportUtils.formatNumberToFixed(
              x.portfolioTotal?.adminInterest
            )
          : 0;
        portfolioTotal.receivedProgramAdminFee = x.portfolioTotal
          ?.programAdminFee
          ? this.exportUtils.formatNumberToFixed(
              x.portfolioTotal?.programAdminFee
            )
          : 0;
        portfolioTotal.receivedPeqAdminFee = x.portfolioTotal?.peqAdminFee
          ? this.exportUtils.formatNumberToFixed(x.portfolioTotal?.peqAdminFee)
          : 0;
        portfolioTotal.receivedLateFee = x.portfolioTotal?.lateFee
          ? this.exportUtils.formatNumberToFixed(x.portfolioTotal?.lateFee)
          : 0;
        portfolioTotal.receivedTotalAmount = x.portfolioTotal?.totalAmount
          ? this.exportUtils.formatNumberToFixed(x.portfolioTotal?.totalAmount)
          : 0;
        portfolioTotal.endingBalance = x.portfolioTotal?.currentLoanBalance
          ? this.exportUtils.formatNumberToFixed(
              x.portfolioTotal?.currentLoanBalance
            )
          : 0;

        const portfolioTotalRow = worksheet.addRow(portfolioTotal);
        portfolioTotalRow.fill = ExcelTemplate.REMITANCE_ROW;
        worksheet.mergeCells(
          `A${portfolioTotalRow.number}:D${portfolioTotalRow.number}`
        );
        portfolioTotalRow.font = { size: 11, bold: true };
        worksheet.addRow();
      });

    // data
    //   .filter(x => !x.projects)
    //   .map(x => {
    //#region remittance grand Total

    const y = Object.create({});
    y.name = 'Remittance Grand Total';
    y.receivedPrincipalAmount = remittanceData?.remittanceGrandTotal
      ?.principalAmount
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.remittanceGrandTotal?.principalAmount
        )
      : 0;
    y.endingBalance = remittanceData?.remittanceGrandTotal?.endingBalance
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.remittanceGrandTotal?.endingBalance
        )
      : 0;

    const totalRow = worksheet.addRow(y);
    worksheet.mergeCells(`A${totalRow.number}:D${totalRow.number}`);
    totalRow.font = { size: 11, bold: true };
    totalRow.fill = ExcelTemplate.REMITANCE_ROW_TOTAL;

    // portfolio grand total
    const portfolioGrandTotal = Object.create({});
    portfolioGrandTotal.name = 'Portfolio Grand Total';
    portfolioGrandTotal.receivedPrincipalAmount = remittanceData
      ?.portfolioGrandTotal?.totalAmountFinanced
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.totalAmountFinanced
        )
      : 0;
    portfolioGrandTotal.receivedInterestAmount = remittanceData
      ?.portfolioGrandTotal?.interestAmount
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.interestAmount
        )
      : 0;
    portfolioGrandTotal.receivedPehInterest = remittanceData
      ?.portfolioGrandTotal?.pehInterest
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.pehInterest
        )
      : 0;
    portfolioGrandTotal.receivedPeServicing = remittanceData
      ?.portfolioGrandTotal?.peServicing
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.peServicing
        )
      : 0;
    // portfolioGrandTotal.receivedAdminInterest = x.overallPortfolioAdminInterest
    //   ? this.exportUtils.formatNumberToFixed(x.overallPortfolioAdminInterest)
    //   : 0;
    portfolioGrandTotal.receivedAdminInterest = remittanceData
      ?.portfolioGrandTotal?.adminInterest
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.adminInterest
        )
      : 0;
    portfolioGrandTotal.receivedProgramAdminFee = remittanceData
      ?.portfolioGrandTotal?.programAdminFee
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.programAdminFee
        )
      : 0;
    portfolioGrandTotal.receivedPeqAdminFee = remittanceData
      ?.portfolioGrandTotal?.peqAdminFee
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.peqAdminFee
        )
      : 0;
    portfolioGrandTotal.receivedLateFee = remittanceData?.portfolioGrandTotal
      ?.lateFee
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.lateFee
        )
      : 0;
    portfolioGrandTotal.receivedTotalAmount = remittanceData
      ?.portfolioGrandTotal?.totalAmount
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.totalAmount
        )
      : 0;

    portfolioGrandTotal.endingBalance = remittanceData?.portfolioGrandTotal
      ?.currentLoanBalance
      ? this.exportUtils.formatNumberToFixed(
          remittanceData?.portfolioGrandTotal?.currentLoanBalance
        )
      : 0;

    const portfolioGrandTotalTotalRow = worksheet.addRow(portfolioGrandTotal);
    worksheet.mergeCells(
      `A${portfolioGrandTotalTotalRow.number}:D${portfolioGrandTotalTotalRow.number}`
    );
    portfolioGrandTotalTotalRow.font = { size: 11, bold: true };

    portfolioGrandTotalTotalRow.fill = ExcelTemplate.REMITANCE_ROW_TOTAL;
    worksheet.addRow();
    //#endregion
    // });

    //#region Set column alignment and formats
    const currencyColumn = [
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
    ];
    const dateColumn = ['B', 'C'];
    const leftAlignColumn = ['A', 'P'];
    const centerAlignColumn = ['D'];
    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(
      worksheet,
      centerAlignColumn,
      ExcelTemplate.CENTER_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      leftAlignColumn,
      ExcelTemplate.LEFT_ALIGN
    );
    //#endregion

    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    this.generateAutoWidth(worksheet);
    return worksheet;
  }
  convertToDelinquencyTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.getRow(1).height = 100;

    const specificRateCell = ['F', 'G'];
    const currencyColumn = [
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
    ];
    const centerAlignColumn = ['B', 'C', 'D', 'E'];
    const wrapTextColumn = ['Q', 'S'];
    const specificCurrencyCell = ['F', 'G'];

    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(
      worksheet,
      centerAlignColumn,
      ExcelTemplate.CENTER_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      wrapTextColumn,
      ExcelTemplate.WRAP_TEXT
    );
    data
      .filter((w) => w.projects)
      .map((x, i) => {
        x.name = x.portfolioName;
        const row = worksheet.addRow(x);
        worksheet.mergeCells(`B${row.number}:Q${row.number}`);
        row.font = { size: 13, bold: true };
        x.projects.map((y: any) => {
          const dataList = y.delinquencies;
          dataList.map((z: any) => {
            z.name = y.name;
            z.expectedPaymentDate =
              z.expected && z.expected.paymentDate
                ? z.expected.paymentDate
                : 'N/A';
            z.expectedAssessmentDueDate =
              z.expected && z.expected.assessmentDueDate
                ? z.expected.assessmentDueDate
                : 'N/A';
            z.receivedPaymentDate =
              z.received && z.received.paymentDate
                ? z.received.paymentDate
                : z.daysOverdue > 0
                ? 'Overdue'
                : '-';
            z.daysOverdue = z && z.daysOverdue > 0 ? z.daysOverdue : '-';
            z.expectedPrincipalAmount =
              z.expected && z.expected.principalAmount
                ? this.exportUtils.formatNumberToFixed(
                    z.expected.principalAmount
                  )
                : 0;
            z.expectedInterestAmount =
              z.expected && z.expected.interestAmount
                ? this.exportUtils.formatNumberToFixed(
                    z.expected.interestAmount
                  )
                : 0;
            z.pehInterest =
              z.expected && z.expected.pehInterest
                ? this.exportUtils.formatNumberToFixed(z.expected.pehInterest)
                : 0;
            z.peServicing =
              z.expected && z.expected.peServicing
                ? this.exportUtils.formatNumberToFixed(z.expected.peServicing)
                : 0;
            z.adminInterest =
              z.expected && z.expected.adminInterest
                ? this.exportUtils.formatNumberToFixed(z.expected.adminInterest)
                : 0;

            z.expectedProgramAdminFee =
              z.expected && z.expected.programAdminFee
                ? this.exportUtils.formatNumberToFixed(
                    z.expected.programAdminFee
                  )
                : 0;
            z.expectedPeqAdminFee =
              z.expected && z.expected.peqAdminFee
                ? this.exportUtils.formatNumberToFixed(z.expected.peqAdminFee)
                : 0;
            z.expectedTotalAmount =
              z.expected && z.expected.totalAmount
                ? this.exportUtils.formatNumberToFixed(z.expected.totalAmount)
                : 0;
            z.receivedTotalAmount =
              z.received && z.received.totalAmount
                ? this.exportUtils.formatNumberToFixed(z.received.totalAmount)
                : 0;
            z.endingBalance =
              z && z.endingBalance
                ? this.exportUtils.formatNumberToFixed(z.endingBalance)
                : 0;
            z.billingParty = y && y.billingParty ? y.billingParty : 'N/A';
            z.county = y && y.county ? y.county : 'N/A';
            z.totalAmountFinanced =
              y && y.totalAmountFinanced
                ? this.exportUtils.formatNumberToFixed(y.totalAmountFinanced)
                : 0;
            z.currentLoanBalance =
              y && y.currentLoanBalance > 0
                ? this.exportUtils.formatNumberToFixed(y.currentLoanBalance)
                : 0;
            z.delinquencyNotes = y.delinquencyNotes
              ? y.delinquencyNotes.replace(/<[^>]*>/g, '').trim()
              : 'N/A';
            const row = worksheet.addRow(z);
          });
        });

        // x.projects.map(y=>{
        //   y.overallExpectedTotalPayment= y.
        // });
        //Row for Total Original Amount and Borrower balance
        //
        const dt = Object.create({});
        dt.daysOverdue = 'Delinquency Total';
        dt.totalAmountFinanced = x.delinquentTAFTotal
          ? this.exportUtils.formatNumberToFixed(x.delinquentTAFTotal)
          : 0;
        dt.currentLoanBalance = x.delinquentCurrentLoanBalanceTotal
          ? this.exportUtils.formatNumberToFixed(
              x.delinquentCurrentLoanBalanceTotal
            )
          : 0;
        const delinquencyTotalRow = worksheet.addRow(dt);
        delinquencyTotalRow.font = { size: 10, bold: true };

        const pt = Object.create({});
        pt.daysOverdue = 'Portfolio Total';
        pt.totalAmountFinanced = x.allPortfolioTotalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(
              x.allPortfolioTotalAmountFinanced
            )
          : 0;
        pt.currentLoanBalance = x.allPortfolioCurrentLoanBalance
          ? this.exportUtils.formatNumberToFixed(
              x.allPortfolioCurrentLoanBalance
            )
          : 0;
        const portFolioTotalRow = worksheet.addRow(pt);
        portFolioTotalRow.font = { size: 10, bold: true };

        const pp = Object.create({});
        pp.daysOverdue = '% Portfolio';
        pp.totalAmountFinanced = x.percentTAFTotalPortfolio
          ? this.exportUtils.formatNumberToFixed(x.percentTAFTotalPortfolio)
          : 0;
        pp.currentLoanBalance = x.percentCurrentLoanBalance
          ? this.exportUtils.formatNumberToFixed(x.percentCurrentLoanBalance)
          : 0;

        const delinquneyPercentageRow = worksheet.addRow(pp);
        delinquneyPercentageRow.font = { size: 10, bold: true };
        this.formatSpecificCell(
          delinquneyPercentageRow,
          specificRateCell,
          ExcelTemplate.RATE
        );

        worksheet.addRow();
      });
    // grand total
    data
      .filter((x) => !x.projects)
      .map((x) => {
        //#region At Risk Grand Total
        const deqRep = Object.create({});
        deqRep.daysOverdue = 'Delinquency Grand Total';
        deqRep.totalAmountFinanced = x.allDelinquentTAFTotal
          ? this.exportUtils.formatNumberToFixed(x.allDelinquentTAFTotal)
          : 0;
        deqRep.currentLoanBalance = x.allDelinquentCurrentLoanBalanceTotal
          ? this.exportUtils.formatNumberToFixed(
              x.allDelinquentCurrentLoanBalanceTotal
            )
          : 0;

        const delinquencyTotalRow = worksheet.addRow(deqRep);

        delinquencyTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          delinquencyTotalRow,
          specificCurrencyCell,
          ExcelTemplate.CURRENCY
        );
        //#endregion
        //#region Portfolio Grand Total
        const pgt = Object.create({});
        pgt.daysOverdue = 'Portfolio Grand Total';
        pgt.totalAmountFinanced = x.allPortfolioTotalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(
              x.allPortfolioTotalAmountFinanced
            )
          : 0;
        pgt.currentLoanBalance = x.allPortfolioCurrentLoanBalance
          ? this.exportUtils.formatNumberToFixed(
              x.allPortfolioCurrentLoanBalance
            )
          : 0;

        const portfolioGrandTotalRow = worksheet.addRow(pgt);

        portfolioGrandTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          portfolioGrandTotalRow,
          specificCurrencyCell,
          ExcelTemplate.CURRENCY
        );
        //#endregion
        //#region Grand Total
        const ppgt = Object.create({});
        ppgt.daysOverdue = '% Portfolio Grand Total';
        ppgt.totalAmountFinanced = x.allPercentTAFTotalPortfolio
          ? this.exportUtils.formatNumberToFixed(x.allPercentTAFTotalPortfolio)
          : 0;
        ppgt.currentLoanBalance = x.allPercentCurrentLoanBalance
          ? this.exportUtils.formatNumberToFixed(x.allPercentCurrentLoanBalance)
          : 0;

        const percentPortfolioGrandTotalRow = worksheet.addRow(ppgt);

        percentPortfolioGrandTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          percentPortfolioGrandTotalRow,
          specificRateCell,
          ExcelTemplate.RATE
        );

        worksheet.addRow();
        //#endregion
      });

    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);

    return worksheet;
  }

  createOuterBorderMultipleCells(start: any, end: any, worksheet: any) {
    const startRow = start.row; // row number
    const endRow = end.row;
    const startCol = start._column.letter; // column letter
    const endCol = end._column.letter;
    const topRightCell = worksheet.getCell(`${endCol}${startRow}`);
    const bottomLeftCell = worksheet.getCell(`${startCol}${endRow}`);
    const noOfCols = end.col - start.col; // no of columns - 1 inside border

    // setting border in corner cells
    start.border = ExcelTemplate.BORDER.TOP_LEFT;
    topRightCell.border = ExcelTemplate.BORDER.TOP_RIGHT;
    bottomLeftCell.border = ExcelTemplate.BORDER.BOTTOM_LEFT;
    end.border = ExcelTemplate.BORDER.BOTTOM_RIGHT;

    // setting border in left most cells
    for (let i = startRow + 1; i < endRow; i++) {
      const cell = worksheet.getCell(`${startCol}${i}`);
      cell.border = ExcelTemplate.BORDER.LEFT;
    }

    // setting border in right most cells
    for (let i = startRow + 1; i < endRow; i++) {
      const cell = worksheet.getCell(`${endCol}${i}`);
      cell.border = ExcelTemplate.BORDER.RIGHT;
    }

    // setting border in top cells
    let startColBorder = start.col + 1;
    for (let i = 1; i < noOfCols; i++) {
      const cell = worksheet.getCell(
        `${this.utils.getExcelColumnName(startColBorder)}${startRow}`
      );
      cell.border = ExcelTemplate.BORDER.TOP;
      startColBorder++;
    }

    // setting brder in bottom cells
    startColBorder = start.col + 1;
    for (let i = 1; i < noOfCols; i++) {
      const cell = worksheet.getCell(
        `${this.utils.getExcelColumnName(startColBorder)}${endRow}`
      );
      cell.border = ExcelTemplate.BORDER.BOTTOM;
      startColBorder++;
    }

    return worksheet;
  }

  convertToAmortizationScheduleTemplate(
    worksheet: any,
    data: any[],
    headerRow: any
  ) {
    worksheet.getRow(1).height = 150;
    headerRow.height = 70;
    headerRow.alignment = { wrapText: true };
    const reportingStartCell = headerRow.getCell('R');
    const paymentStartCell = headerRow.getCell('T');
    let reportingEndCell;
    let paymentEndCell;
    headerRow.getCell('O').font = ExcelTemplate.BLUE_FONT;
    headerRow.getCell('P').font = ExcelTemplate.BLUE_FONT;
    headerRow.getCell('N').font = ExcelTemplate.RED_FONT;
    headerRow.getCell('Q').font = ExcelTemplate.RED_FONT;
    data.map((r, i) => {
      r.isAmortizing = r.isAmortizing ? r.isAmortizing : 'No';
      r.paymentDueThisPeriod = r.paymentDueThisPeriod
        ? r.paymentDueThisPeriod
        : 'No';
      const row = worksheet.addRow(r);
      if (r.principalPayment == '$0.00') {
        row.fill = ExcelTemplate.GREEN_COLUMN;
      }
      const beginning = row.getCell('J');
      const isAmortizing = row.getCell('N');
      const lastSecurePayment = row.getCell('O');
      const nextSecurePayment = row.getCell('P');
      const paymentDue = row.getCell('Q');
      const netAccuredRP = row.getCell('R');
      const servicingFeeRP = row.getCell('S');
      const netAccuredPP = row.getCell('T');
      const servicingFeePP = row.getCell('U');
      const programAdmin = row.getCell('V');
      const expected = row.getCell('W');
      const collectedPaymentDate = row.getCell('AD');
      const collectedPrincipalPayment = row.getCell('AE');
      const collectedInterestPayment = row.getCell('AF');
      const collectedAdminFee = row.getCell('AG');
      const collectedPEServicingFee = row.getCell('AH');

      beginning.font = ExcelTemplate.BLUE_FONT;
      isAmortizing.font = ExcelTemplate.RED_FONT;
      lastSecurePayment.font = ExcelTemplate.BLUE_FONT;
      nextSecurePayment.font = ExcelTemplate.BLUE_FONT;
      paymentDue.font = ExcelTemplate.RED_FONT;
      netAccuredRP.fill = ExcelTemplate.GREEN_COLUMN;
      servicingFeeRP.fill = ExcelTemplate.GREEN_COLUMN;
      netAccuredPP.fill = ExcelTemplate.BLUE_COLUMN;
      servicingFeePP.fill = ExcelTemplate.BLUE_COLUMN;
      programAdmin.fill = ExcelTemplate.BLUE_COLUMN;
      expected.font = ExcelTemplate.BLUE_FONT;
      collectedPaymentDate.fill = ExcelTemplate.YELLOW_COLUMN;
      collectedPrincipalPayment.fill = ExcelTemplate.YELLOW_COLUMN;
      collectedInterestPayment.fill = ExcelTemplate.YELLOW_COLUMN;
      collectedAdminFee.fill = ExcelTemplate.YELLOW_COLUMN;
      collectedPEServicingFee.fill = ExcelTemplate.YELLOW_COLUMN;

      if (i == data.length - 1) {
        reportingEndCell = row.getCell('S');
        paymentEndCell = row.getCell('Z');
      }
    });
    worksheet = this.createOuterBorderMultipleCells(
      reportingStartCell,
      reportingEndCell,
      worksheet
    );
    worksheet = this.createOuterBorderMultipleCells(
      paymentStartCell,
      paymentEndCell,
      worksheet
    );
    const rateColumns = ['E', 'F', 'G', 'H'];
    const currencyColumn = [
      'I',
      'J',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'AA',
      'AB',
      'AE',
      'AF',
      'AG',
      'AH',
    ];
    const dateColumn = ['M', 'O', 'P', 'AD'];

    rateColumns.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.RATE;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    currencyColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    dateColumn.map((col) => {
      worksheet.getColumn(col).alignment = { horizontal: 'center' };
    });

    this.generateAutoWidth(worksheet, 'portfolioCfReport');
    worksheet.views = [
      { state: 'frozen', xSplit: 4, ySplit: 0 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];
    return worksheet;
  }
  mapRowData(data: any, projectList: any, type: string) {
    if (type == 'portfolioCf') {
      data.map((x: any) => {
        x.totalInvestment = x.totalInvestment > 0 ? x.totalInvestment : '-';
        x.totalInterest = x.totalInterest > 0 ? x.totalInterest : '-';
        x.totalPrincipal = x.totalPrincipal > 0 ? x.totalPrincipal : '-';
        projectList.forEach((project: any) => {
          x['interest_' + project.projectName] =
            x.interest[project.projectName];
          x['investment_' + project.projectName] =
            x.investment[project.projectName];
          x['principal_' + project.projectName] =
            x.principal[project.projectName];
        });
      });
    } else if (type == 'peqCf') {
      data.map((x: any) => {
        x.totalServicingFee =
          x.totalServicingFee > 0 ? x.totalServicingFee : '-';
        x.totalAdminFee = x.totalAdminFee > 0 ? x.totalAdminFee : '-';
        x.totalPEQCharge = x.totalPEQCharge > 0 ? x.totalPEQCharge : '-';
        projectList.forEach((project: any) => {
          x['pmt_' + project.id] = x.pmt[project.id];
          x['servicingFee_' + project.id] = x.servicingFee[project.id];
          x['adminFee_' + project.id] = x.adminFee[project.id];
          x['peqCharge_' + project.id] = x.peqCharge[project.id];
        });
      });
    }

    return data;
  }

  convertToPortfolioCfReportTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    topHeaderRow: any,
    portfolioHeaderRow: any,
    reportHeaderKeys: any,
    selectedProjectList: any
  ) {
    data = JSON.parse(JSON.stringify(data));
    data = this.mapRowData(data, selectedProjectList, 'portfolioCf');
    data.map((r, i) => {
      const row = worksheet.addRow(r);
      row.alignment = { horizontal: 'right' };
      row.numFmt = ExcelTemplate.CURRENCY;

      const totalInvestment = row.getCell('B');
      const totalInterest = row.getCell('C');
      const totalPrincipal = row.getCell('D');

      totalInvestment.fill = ExcelTemplate.LIGHT_GREY_FILL;
      totalInterest.fill = ExcelTemplate.LIGHT_GREY_FILL;
      totalPrincipal.fill = ExcelTemplate.LIGHT_GREY_FILL;

      for (let index = 0; index < reportHeaderKeys.length; index++) {
        if (
          r.interest[reportHeaderKeys[index].value] &&
          r.sweep[reportHeaderKeys[index].value]
        ) {
          worksheet.getCell(
            `${this.utils.getExcelColumnName(index + 1)}${row.number}`
          ).fill = ExcelTemplate.LIGHT_YELLOW_COLUMN;
        }
      }
    });
    headerRow.fill = ExcelTemplate.LIGHT_GREY_FILL;
    topHeaderRow.fill = ExcelTemplate.GREY_FILL;
    // portfolioHeaderRow.fill = ExcelTemplate.GREY_FILL;

    this.generateAutoWidth(worksheet);
    worksheet.views = [
      { state: 'frozen', xSplit: 4, ySplit: 7 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];
    return worksheet;
  }

  convertToPeqCfReportTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    topHeaderRow: any,
    portfolioHeaderRow: any,
    reportHeaderKeys: any,
    selectedProjectList: any
  ) {
    data = JSON.parse(JSON.stringify(data));
    data = this.mapRowData(data, selectedProjectList, 'peqCf');
    data.map((r, i) => {
      const row = worksheet.addRow(r);
      row.alignment = { horizontal: 'right' };
      row.numFmt = ExcelTemplate.CURRENCY;

      const totalServicingFee = row.getCell('B');
      const totalAdminFee = row.getCell('C');
      const totalPeqCharge = row.getCell('D');

      totalServicingFee.fill = ExcelTemplate.LIGHT_GREY_FILL;
      totalAdminFee.fill = ExcelTemplate.LIGHT_GREY_FILL;
      totalPeqCharge.fill = ExcelTemplate.LIGHT_GREY_FILL;

      for (let index = 0; index < reportHeaderKeys.length; index++) {
        if (
          r.servicingFee[reportHeaderKeys[index].value] &&
          r.sweep[reportHeaderKeys[index].value]
        ) {
          worksheet.getCell(
            `${this.utils.getExcelColumnName(index + 1)}${row.number}`
          ).fill = ExcelTemplate.LIGHT_YELLOW_COLUMN;
        }
      }
    });
    headerRow.fill = ExcelTemplate.LIGHT_GREY_FILL;
    topHeaderRow.fill = ExcelTemplate.GREY_FILL;
    // portfolioHeaderRow.fill = ExcelTemplate.GREY_FILL;

    this.generateAutoWidth(worksheet);
    worksheet.views = [
      { state: 'frozen', xSplit: 4, ySplit: 7 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];
    return worksheet;
  }

  convertToDataTapeTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    this.utils.parseJsonStringify(data).map((x: any, i: any) => {
      let rowData: any = {
        'Project Information': x.headerData,
        Description: x.description,
      };
      Object.keys(x.value).forEach((y) => {
        switch (x.type) {
          case DATA_TAPE_CONSTANTS.COLUMN_TYPES.Y_N:
            // rowData[y] = this.ynPipe.transform(x.value[y]?.value);
            rowData[y] = x.value[y]?.value ? 'Y' : 'N';
            break;
          case DATA_TAPE_CONSTANTS.COLUMN_TYPES.EMPTY:
            rowData[y] = null;
            break;
          case DATA_TAPE_CONSTANTS.COLUMN_TYPES.CURRENCY:
          case DATA_TAPE_CONSTANTS.COLUMN_TYPES.RATE:
          case DATA_TAPE_CONSTANTS.COLUMN_TYPES.NUMBER:
            rowData[y] = x.value[y]?.value ? x.value[y]?.value : 0;
            break;
          case DATA_TAPE_CONSTANTS.COLUMN_TYPES.CUSTOM:
            switch (x.key) {
              case 'projectAndPropertyInformation_savingsToInvestmentRatio':
                rowData[y] = x.value[y]?.value ? x.value[y]?.value : 'N/A';
                break;
              case 'financingInformation_isNoticeOfPACEFinancingSentToLender':
                rowData[y] = x.value[y]?.value ? 'Y' : 'N/A';
                break;
              case 'majorRehabsOrNewConstructionPropertiesInformation_constructionDelays':
                rowData[y] = x.value[y]?.value ? x.value[y].value : 'N';
                break;
              default:
                rowData[y] = x.value[y]?.value ? x.value[y]?.value : 'N/A';
            }
            break;
          default:
            rowData[y] = x.value[y]?.value ? x.value[y]?.value : 'N/A';
        }
      });
      const row = worksheet.addRow(rowData);
      row.eachCell((cell: any, colNumber: number) => {
        if (colNumber == 1 || colNumber == 2) {
          cell.alignment = {
            horizontal: 'left',
            vertical: 'middle',
            wrapText: true,
          };
          if (x.type === DATA_TAPE_CONSTANTS.COLUMN_TYPES.HEADER) {
            cell.font = { ...ExcelTemplate.BOLD, ...ExcelTemplate.UNDERLINE };
          }
        } else {
          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
            wrapText: true,
          };
          switch (x.type) {
            case DATA_TAPE_CONSTANTS.COLUMN_TYPES.CURRENCY:
              cell.numFmt = ExcelTemplate.CURRENCY;
              break;
            case DATA_TAPE_CONSTANTS.COLUMN_TYPES.RATE:
              cell.numFmt = ExcelTemplate.RATE;
              break;
            case DATA_TAPE_CONSTANTS.COLUMN_TYPES.NUMBER:
              cell.numFmt = ExcelTemplate.NUMBER;
              break;
            case DATA_TAPE_CONSTANTS.COLUMN_TYPES.CUSTOM:
              switch (x.key) {
                case 'projectAndPropertyInformation_savingsToInvestmentRatio':
                  cell.numFmt = ExcelTemplate.NUMBER;
                  break;
              }
              break;
          }
        }
      });
    });
    headerRow.fill = ExcelTemplate.LIGHT_GREY_FILL;
    headerRow.alignment = {
      ...headerRow.alignment,
      wrapText: true,
      vertical: 'middle',
    };

    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);

    worksheet.views = [
      { state: 'frozen', xSplit: 2, ySplit: 4 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];
    return worksheet;
  }
  convertToDashboardViewTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    reportHeader: any,
    totalSum: any,
    grouping: boolean
  ) {
    let sortedData: any = [];
    data = JSON.parse(JSON.stringify(data));
    data.map((x, i) => {
      if (x.projects && x.projects.length > 0) {
        if (grouping) {
          const groupName = worksheet.addRow();
          let groupNameRowNumber = worksheet.getRow(groupName.number).number;
          const cell = worksheet.getCell(`A${groupNameRowNumber}`);
          cell.value = x.sortBy;
          cell.font = { bold: true };
        }
        x.project_name = x.subPhaseName;

        // const phaseRow = worksheet.addRow(x);
        // phaseRow.font = { size: 13, bold: true };
      }
      x.projects.map((y: any) => {
        // if (y.values.asset_deposits_amount) {
        //   let commaSepValue = '';
        //   y.values.asset_deposits_amount.map(z => {
        //     if (z && z != 'null')
        //       commaSepValue = commaSepValue + '$' + z + ', ';
        //   })
        //   y.values.asset_deposits_amount = commaSepValue.substring(0, commaSepValue.length - 2);
        // }
        if (y.values.asset_deposits_date)
          y.values.asset_deposits_date = y.values.asset_deposits_date
            .filter((x: any) => x != null)
            .join(', ');
        if (y.values.asset_nextSteps)
          y.values.asset_nextSteps = y.values.asset_nextSteps
            .replace(/<[^>]*>/g, '')
            .trim();
        if (y.values.asset_impact_eeNotes)
          y.values.asset_impact_eeNotes = y.values.asset_impact_eeNotes
            .replace(/<[^>]*>/g, '')
            .trim();
        if (y.values.program_defaultAndForeclosure)
          y.values.program_defaultAndForeclosure =
            y.values.program_defaultAndForeclosure
              .replace(/<[^>]*>/g, '')
              .trim();
        if (y.values.program_billingAndCollectionDefinition)
          y.values.program_billingAndCollectionDefinition =
            y.values.program_billingAndCollectionDefinition
              .replace(/<[^>]*>/g, '')
              .trim();
        if (y.values.flowOfFunds)
          y.values.flowOfFunds = y.values.flowOfFunds
            .replace(/<[^>]*>/g, '')
            .trim();
        if (y.values.program_sirDefinition)
          y.values.program_sirDefinition = y.values.program_sirDefinition
            .replace(/<[^>]*>/g, '')
            .trim();
        if (y.values.program_termDefinition)
          y.values.program_termDefinition = y.values.program_termDefinition
            .replace(/<[^>]*>/g, '')
            .trim();
        if (y.values.program_notes)
          y.values.program_notes = y.values.program_notes
            .replace(/<[^>]*>/g, '')
            .trim();
        if (y.values.program_servicingDescription)
          y.values.program_servicingDescription =
            y.values.program_servicingDescription
              .replace(/<[^>]*>/g, '')
              .trim();

        if (grouping) {
          const row = worksheet.addRow(y.values);
        }
        sortedData.push(y.values);
      });
      if (grouping) {
        if (x.projects && x.projects.length > 0) {
          const sums = { ...x.projectSums, ...x.weightedAvgRates };
          const sumRow = worksheet.addRow(sums);
          sumRow.font = { bold: true };
          worksheet.addRow();
        }
      }
    });

    if (!grouping) {
      sortedData = sortedData.map((x: any) => {
        x.project_name = String(x.project_name); //convert type number to string if there is any number type project_name
        return x;
      });

      sortedData.sort((a: any, b: any) => {
        if (a.project_name && b.project_name) {
          const name1 = a.project_name.trim().toUpperCase();
          const name2 = b.project_name.trim().toUpperCase();

          return name1.localeCompare(name2);
        }
      });

      sortedData.forEach((x: any) => {
        const row = worksheet.addRow(x);
      });
    }

    const totalSumRow = worksheet.addRow(totalSum);
    totalSumRow.font = { bold: true };
    this.generateAutoWidth(worksheet);
    headerRow.fill = ExcelTemplate.LIGHT_GREY_FILL;
    totalSumRow.fill = ExcelTemplate.LIGHT_GREY_FILL;
    reportHeader.map((x: any, i: any) => {
      if (x.type == 'CURRENCY') {
        worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).numFmt =
          ExcelTemplate.CURRENCY;
        worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).alignment = {
          horizontal: 'right',
          wrapText: true,
        };
      } else if (x.type == 'RATE') {
        worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).numFmt =
          ExcelTemplate.RATE;
        worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).alignment = {
          horizontal: 'right',
          wrapText: true,
        };
      } else if (x.type == 'DATE' || x.type === 'BOOLEAN') {
        worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).alignment = {
          horizontal: 'center',
          wrapText: true,
        };
      } else if (x.type == 'STRING') {
        if (x.key == 'asset_trackerLink') {
          worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).width = 40;
        } else {
          worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).width = 40;
        }
        worksheet.getColumn(this.utils.getExcelColumnName(i + 1)).alignment = {
          wrapText: true,
        };
      }
    });
    worksheet.views = [
      { state: 'frozen', xSplit: 1, ySplit: 4 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];
    return worksheet;
  }

  convertToAssetReportTemplate(worksheet: any, data: any, portfolioCode: any) {
    worksheet.spliceRows(4, 0, []);
    const scheduleTitleRow = worksheet.getRow(4);
    worksheet.mergeCells(
      `A${scheduleTitleRow.number}: C${scheduleTitleRow.number}`
    );
    worksheet.getCell(`A${scheduleTitleRow.number}`).value = 'Inputs';
    worksheet.getCell(`A${scheduleTitleRow.number}`).fill =
      ExcelTemplate.BLUE_FILL;
    worksheet.mergeCells(
      `E${scheduleTitleRow.number}: O${scheduleTitleRow.number}`
    );
    worksheet.getCell(`E${scheduleTitleRow.number}`).value = `${
      portfolioCode ?? ''
    } Amortization Schedule`;
    worksheet.getCell(`E${scheduleTitleRow.number}`).fill =
      ExcelTemplate.BLUE_FILL;
    worksheet.mergeCells(
      `Q${scheduleTitleRow.number}: X${scheduleTitleRow.number}`
    );
    worksheet.getCell(`Q${scheduleTitleRow.number}`).value =
      'Client Assessment Payment Schedule';
    worksheet.getCell(`Q${scheduleTitleRow.number}`).fill =
      ExcelTemplate.BLACK_FILL;
    scheduleTitleRow.font = ExcelTemplate.WHITE_FONT;

    data.map((r: any, i: any) => {
      r = this.roundUpOldMethod(r);
      const row = worksheet.addRow(r);
      if (
        r.pmt.toString().toLowerCase() === 'cap i end' ||
        i === data.length - 1
      ) {
        for (let index = 5; index <= 24; index++) {
          if (this.utils.getExcelColumnName(index) !== 'P') {
            worksheet.getCell(
              `${this.utils.getExcelColumnName(index)}${row.number}`
            ).border = {
              bottom: { style: 'double' },
            };
          }
        }
      }
      if (isNaN(+r.pmt)) {
        for (let index = 5; index <= 24; index++) {
          worksheet.getCell(
            `${this.utils.getExcelColumnName(index)}${row.number}`
          ).fill = ExcelTemplate.YELLOW_COLUMN;
        }
      }
    });

    this.generateAutoWidth(worksheet, 'assetReport');

    //blank columns
    worksheet.getColumn('D').width = 2;
    worksheet.getColumn('L').width = 2;
    worksheet.getColumn('P').width = 2;

    //For asset Details
    const assetColumns = ['A', 'B', 'C'];
    worksheet = this.createOuterBorderMultipleCells(
      worksheet.getCell(`${assetColumns[0]}6`),
      worksheet.getCell(`${assetColumns[2]}41`),
      worksheet
    );

    worksheet.mergeCells(`A6:C6`); // Financing Amount
    worksheet.getCell(`A6`).fill = ExcelTemplate.LIGHT_BLUE_FILL;
    worksheet.getCell(`A6`).font = { bold: true };
    worksheet.getCell(`A6`).border = ExcelTemplate.BORDER.BOTTOM;
    worksheet.getCell(`A6`).border = ExcelTemplate.BORDER.ALL;

    worksheet.getCell(`C8`).border = {
      bottom: { style: 'thin' },
      right: { style: 'medium' },
    }; // Capitalized Interest

    assetColumns.forEach((col) => {
      worksheet.getCell(`${col}9`).font = { bold: true }; //Total Amount Financed

      worksheet.getCell(`${col}20`).fill = ExcelTemplate.GREY_COLUMN;
      worksheet.getCell(`${col}20`).font = { bold: true };

      if (col === 'C') {
        worksheet.getCell(`${col}39`).border = {
          bottom: { style: 'thin' },
          top: { style: 'thin' },
          right: { style: 'medium' },
        };
        worksheet.getColumn(col).alignment = { horizontal: 'right' };
        worksheet.getCell(`${col}1`).alignment = { horizontal: 'left' };
        worksheet.getCell(`${col}15`).border = {
          bottom: { style: 'thin' },
          right: { style: 'medium' },
        };
      } else
        worksheet.getCell(`${col}39`).border = {
          bottom: { style: 'thin' },
          top: { style: 'thin' },
        };
    });

    worksheet.mergeCells(`A11:C11`); // Assessment Terms
    worksheet.getCell(`A11`).fill = ExcelTemplate.LIGHT_BLUE_FILL;
    worksheet.getCell(`A11`).font = { bold: true };
    worksheet.getCell(`A11`).border = ExcelTemplate.BORDER.ALL;

    worksheet.mergeCells(`A19:C19`); // Dates
    worksheet.getCell(`A19`).fill = ExcelTemplate.LIGHT_BLUE_FILL;
    worksheet.getCell(`A19`).font = { bold: true };
    worksheet.getCell(`A19`).border = ExcelTemplate.BORDER.ALL;

    worksheet.mergeCells(`A31:C31`); //Annual Administartion Fees
    worksheet.getCell(`A31`).fill = ExcelTemplate.LIGHT_BLUE_FILL;
    worksheet.getCell(`A31`).font = { bold: true };
    worksheet.getCell(`A31`).border = ExcelTemplate.BORDER.ALL;

    // for cell font
    const blueFontCells = [
      'C7',
      'C10',
      'C12',
      'C14',
      'C16',
      'C17',
      'C21',
      'C22',
      'C23',
      'C24',
      'C25',
      'C27',
      'C32',
      'C33',
      'C35',
      'C36',
      'C37',
      'C38',
      'C39',
      'C40',
      'B21',
      'B22',
    ];
    blueFontCells.forEach((cell) => {
      worksheet.getCell(cell).font = ExcelTemplate.BLUE_FONT;
    });

    // for cell fromat
    const rateCells = ['C12', 'C13', 'C14', 'C15', 'C33', 'C35', 'C36'];
    const currencyCell = [
      'C7',
      'C8',
      'C9',
      'C10',
      'C18',
      'C32',
      'C37',
      'C38',
      'C39',
      'C40',
      'C41',
    ];
    const currencyColumn = [
      'E',
      'G',
      'H',
      'I',
      'J',
      'K',
      'M',
      'N',
      'O',
      'Q',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
    ];
    rateCells.map((cell) => {
      worksheet.getCell(cell).numFmt = ExcelTemplate.RATE;
    });
    currencyCell.map((cell) => {
      worksheet.getCell(cell).numFmt = ExcelTemplate.CURRENCY;
    });
    worksheet.getColumn('A').width = 40;
    worksheet.getColumn('B').width = 12;
    worksheet.getColumn('C').width = 15;
    currencyColumn.map((col) => {
      worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
      worksheet.getColumn(col).alignment = { horizontal: 'right' };
    });
    worksheet.getColumn(`E`).alignment = { horizontal: 'center' };
    worksheet.getColumn(`Q`).alignment = { horizontal: 'center' };

    worksheet.getCell(`E4`).alignment = { horizontal: 'left' };
    worksheet.getCell(`Q4`).alignment = { horizontal: 'left' };

    // alignment fixes
    worksheet.getColumn('C').alignment = { horizontal: 'right' };
    worksheet.getColumn('B').alignment = { horizontal: 'right' };
    worksheet.getColumn('R').alignment = { horizontal: 'left' };

    worksheet.getCell('A1').alignment = { horizontal: 'left' };
    worksheet.getCell('A4').alignment = { horizontal: 'left' };
    worksheet.getCell('A6').alignment = { horizontal: 'left' };
    worksheet.getCell('A11').alignment = { horizontal: 'left' };
    worksheet.getCell('A19').alignment = { horizontal: 'left' };
    worksheet.getCell('A31').alignment = { horizontal: 'left' };

    // to freeze columns
    worksheet.views = [
      { state: 'frozen', xSplit: 3, ySplit: 5 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];

    return worksheet;
  }
  generateAutoWidth(worksheet: any, type?: string) {
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      let dataMax = 0;
      const column = worksheet.columns[i];
      for (
        let j =
          type == 'assetReport' ||
          worksheet.name == 'Portfolio Cashflow Report' ||
          worksheet.name == 'PEQ Cashflow Report'
            ? 6
            : 4;
        j < column.values.length;
        j += 1
      ) {
        const columnLength = column.values[j] ? column.values[j].length : 0;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      }
      column.width = dataMax < 15 ? 20 : dataMax;
    }
    return worksheet;
  }

  generateMinWidth(worksheet: any, type?: any) {
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      let dataMax = 0;
      const column = worksheet.columns[i];
      for (let j = 4; j < column.values.length; j += 1) {
        const columnLength = column.values[j] ? column.values[j].length : 0;
        if (columnLength > dataMax) {
          dataMax = columnLength;
        }
      }
      switch (type) {
        case 'dbrsTemplate':
          column.width = i === 0 ? dataMax : i === 15 ? 100 : 25;
          break;
        case 'highRiskTemplate':
          column.width = i === 0 ? dataMax : i === 7 ? 100 : 25;
          break;
        case 'trancheReportTemplate':
          column.width = i === 0 ? 50 : 20;
          break;
        case 'assessmentBillingTemplate':
          column.width = i === 0 ? 50 : i === 1 ? 35 : i === 5 ? 200 : 25;
          break;
        case 'lenderDatabaseTemplate':
          column.width =
            i === 0
              ? dataMax
              : i === 3 || i === 8
              ? 50
              : i === 15 || i === 16 || i === 11
              ? 100
              : 25;
          break;
        case 'delinquencyReportTemplate':
          column.width = i === 0 ? dataMax : i === 18 ? 150 : 25;
          break;
        case 'prepaymentDetailTemplate':
          column.width = i === 0 || i === 1 ? 50 : 25;
          break;
        case 'prepaymentSummaryTemplate':
          column.width = i === 0 ? 20 : 25;
          break;
        case 'dataTapeTemplate':
          column.width = i === 0 || i === 1 ? 50 : 25;
          break;
        default:
          column.width = i === 0 ? dataMax : 25;
      }
    }
    return worksheet;
  }

  convertToDBRSTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.getRow(1).height = 100;
    headerRow.fill = ExcelTemplate.GREY_FILL;

    data
      .filter((x) => x.projects)
      .map((x, i) => {
        x.projectName = x.portfolioName;
        const row = worksheet.addRow(x);
        worksheet.mergeCells(`B${row.number}:Q${row.number}`);
        row.font = { size: 11, bold: true };
        x.projects.map((z: any) => {
          z.totalDisbursementCommitment = z.totalDisbursementCommitment
            ? this.exportUtils.formatNumberToFixed(
                z.totalDisbursementCommitment
              )
            : 0;
          z.paceProceedsFundedToBorrowerToDate =
            z.paceProceedsFundedToBorrowerToDate
              ? this.exportUtils.formatNumberToFixed(
                  z.paceProceedsFundedToBorrowerToDate
                )
              : 0;
          z.paceProceedsToBeFunded = z.paceProceedsToBeFunded
            ? this.exportUtils.formatNumberToFixed(z.paceProceedsToBeFunded)
            : 0;
          z.percentCommitmentDisbursed = z.percentCommitmentDisbursed
            ? this.exportUtils.formatNumberToFixed(z.percentCommitmentDisbursed)
            : 0;
          z.percentOverallProjectComplete = z.percentOverallProjectComplete
            ? this.exportUtils.formatNumberToFixed(
                z.percentOverallProjectComplete
              )
            : 0;
          z.percentPaceConstructionComplete = z.percentPaceConstructionComplete
            ? this.exportUtils.formatNumberToFixed(
                z.percentPaceConstructionComplete
              )
            : 0;

          const row = worksheet.addRow(z);

          row.alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
          };

          // this.alterFontColorCell(
          //   row,
          //   alterFontColorCell,
          //   ExcelTemplate.RED_FONT_NORMAL
          // );

          if (z.projectName.toLowerCase() == 'portfolio total') {
            row.font = { size: 11, bold: true };
          }
        });
        //#region Portfolio total
        //Row for Total Disbursement Commitment, PACE Proceeds Funded to Borrower to Date, PACE Proceeds to Be Funded
        const y = Object.create({});
        y.projectName = 'Portfolio Total';
        y.totalDisbursementCommitment = x.totalDisbursementCommitment
          ? this.exportUtils.formatNumberToFixed(x.totalDisbursementCommitment)
          : 0;
        y.paceProceedsFundedToBorrowerToDate =
          x.paceProceedsFundedToBorrowerToDate
            ? this.exportUtils.formatNumberToFixed(
                x.paceProceedsFundedToBorrowerToDate
              )
            : 0;
        y.paceProceedsToBeFunded = x.paceProceedsToBeFunded
          ? this.exportUtils.formatNumberToFixed(x.paceProceedsToBeFunded)
          : 0;

        const totalRow = worksheet.addRow(y);

        totalRow.font = { size: 11, bold: true };

        // this.alterFontColorCell(
        //   totalRow,
        //   alterFontColorCell,
        //   ExcelTemplate.RED_FONT_NORMAL
        // );
        worksheet.addRow();
        //#endregion
      });
    data
      .filter((x) => !x.projects)
      .map((x) => {
        //#region Total
        const y = Object.create({});
        y.projectName = 'Total';
        y.totalDisbursementCommitment = x.totalDisbursementCommitment
          ? this.exportUtils.formatNumberToFixed(x.totalDisbursementCommitment)
          : 0;
        y.paceProceedsFundedToBorrowerToDate =
          x.paceProceedsFundedToBorrowerToDate
            ? this.exportUtils.formatNumberToFixed(
                x.paceProceedsFundedToBorrowerToDate
              )
            : 0;
        y.paceProceedsToBeFunded = x.paceProceedsToBeFunded
          ? this.exportUtils.formatNumberToFixed(x.paceProceedsToBeFunded)
          : 0;

        const totalRow = worksheet.addRow(y);

        totalRow.font = { size: 11, bold: true };

        // this.alterFontColorCell(
        //   totalRow,
        //   alterFontColorCell,
        //   ExcelTemplate.RED_FONT_NORMAL
        // );
        worksheet.addRow();
        //#endregion
      });

    //#region Set column alignment and formats
    const dateColumn = ['D', 'E', 'F', 'G', 'N', 'O'];
    const currencyColumn = ['J', 'K', 'L'];
    const rateColumn = ['H', 'I', 'M'];
    const leftAlignColumn = ['A', 'B', 'C', 'P'];
    const wrapTextColumn = ['P'];
    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(worksheet, rateColumn, ExcelTemplate.RATE);
    this.formatSpecificColumns(
      worksheet,
      leftAlignColumn,
      ExcelTemplate.LEFT_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      wrapTextColumn,
      ExcelTemplate.WRAP_TEXT
    );

    //#endregion

    //Text wrap notes column
    worksheet.getColumn('Q').alignment = {
      ...worksheet.getColumn('Q').alignment,
      wrapText: true,
    };

    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);

    // Two columns are frozen at the left of the worksheet
    worksheet.views = [{ state: 'frozen', xSplit: 2, ySplit: 4 }];

    // Set Arial as font
    worksheet.eachRow({ includeEmpty: true }, (row: any) => {
      row.font = { ...row.font, name: 'Arial' };
    });

    return worksheet;
  }

  convertToHighRiskTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.getRow(1).height = 100;
    headerRow.fill = ExcelTemplate.GREY_FILL;

    const dateColumn = ['C', 'D', 'E'];
    const currencyColumn = ['F', 'G'];
    const rateColumn: any = [];
    const leftAlignColumn = ['A', 'H'];
    const centerAlignColumn = ['B'];
    const wrapTextColumn = ['H'];
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(worksheet, rateColumn, ExcelTemplate.RATE);
    this.formatSpecificColumns(
      worksheet,
      leftAlignColumn,
      ExcelTemplate.LEFT_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      centerAlignColumn,
      ExcelTemplate.CENTER_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      wrapTextColumn,
      ExcelTemplate.WRAP_TEXT
    );

    // Always format header row alignment after formatting specific columns
    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    const specificCurrencyCell = ['F', 'G'];
    const specificRateCell = ['F', 'G'];

    data
      .filter((x) => x.projects)
      .map((x, i) => {
        x.projectName = x.portfolioName;
        const row = worksheet.addRow(x);
        worksheet.mergeCells(`B${row.number}:Q${row.number}`);
        row.font = { size: 11, bold: true };
        x.projects.map((z: any) => {
          z.totalAmountFinanced = z.totalAmountFinanced
            ? this.exportUtils.formatNumberToFixed(z.totalAmountFinanced)
            : 0;
          z.totalRemainingBalance = z.totalRemainingBalance
            ? this.exportUtils.formatNumberToFixed(z.totalRemainingBalance)
            : 0;
          z.portfolioTotalAmountFinanced = z.portfolioTotalAmountFinanced
            ? this.exportUtils.formatNumberToFixed(
                z.portfolioTotalAmountFinanced
              )
            : 0;
          z.portfolioTotalRemainingBalance = z.portfolioTotalRemainingBalance
            ? this.exportUtils.formatNumberToFixed(
                z.portfolioTotalRemainingBalance
              )
            : 0;
          z.percentPortfolioTotalAmountFinanced =
            z.percentPortfolioTotalAmountFinanced
              ? this.exportUtils.formatNumberToFixed(
                  z.percentPortfolioTotalAmountFinanced
                )
              : 0;
          z.percentPortfolioTotalRemainingBalance =
            z.percentPortfolioTotalRemainingBalance
              ? this.exportUtils.formatNumberToFixed(
                  z.percentPortfolioTotalRemainingBalance
                )
              : 0;
          z.riskCategory = z.riskCategoryLabel;

          const row = worksheet.addRow(z);
        });
        //#region At Risk total
        //Row for Total Amount Financed, Total Remaining Balance
        const hrt = Object.create({});
        hrt.projectName = `At Risk Total`;
        hrt.totalAmountFinanced = x.totalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(x.totalAmountFinanced)
          : 0;
        hrt.totalRemainingBalance = x.totalRemainingBalance
          ? this.exportUtils.formatNumberToFixed(x.totalRemainingBalance)
          : 0;

        const highRiskTotalRow = worksheet.addRow(hrt);

        highRiskTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          highRiskTotalRow,
          specificCurrencyCell,
          ExcelTemplate.CURRENCY
        );
        //#endregion

        //#region Portfolio total
        //Row for Total Amount Financed, Total Remaining Balance
        const pt = Object.create({});
        pt.projectName = 'Portfolio Total';

        pt.totalAmountFinanced = x.portfolioTotalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(x.portfolioTotalAmountFinanced)
          : 0;
        pt.totalRemainingBalance = x.portfolioTotalRemainingBalance
          ? this.exportUtils.formatNumberToFixed(
              x.portfolioTotalRemainingBalance
            )
          : 0;

        const portfolioTotalRow = worksheet.addRow(pt);

        portfolioTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          portfolioTotalRow,
          specificCurrencyCell,
          ExcelTemplate.CURRENCY
        );
        //#endregion

        //#region % Portfolio
        //Row for Total Amount Financed, Total Remaining Balance
        const pp = Object.create({});
        pp.projectName = '% Portfolio';

        pp.totalAmountFinanced = x.percentPortfolioTotalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(
              x.percentPortfolioTotalAmountFinanced
            )
          : 0;
        pp.totalRemainingBalance = x.percentPortfolioTotalRemainingBalance
          ? this.exportUtils.formatNumberToFixed(
              x.percentPortfolioTotalRemainingBalance
            )
          : 0;

        const percentPortfolioRow = worksheet.addRow(pp);

        percentPortfolioRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          percentPortfolioRow,
          specificRateCell,
          ExcelTemplate.RATE
        );

        worksheet.addRow();
        //#endregion
      });
    data
      .filter((x) => !x.projects)
      .map((x) => {
        //#region At Risk Grand Total
        const hrgt = Object.create({});
        hrgt.projectName = `At Risk Grand Total`;
        hrgt.totalAmountFinanced = x.totalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(x.totalAmountFinanced)
          : 0;
        hrgt.totalRemainingBalance = x.totalRemainingBalance
          ? this.exportUtils.formatNumberToFixed(x.totalRemainingBalance)
          : 0;

        const highRiskGrandTotalRow = worksheet.addRow(hrgt);

        highRiskGrandTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          highRiskGrandTotalRow,
          specificCurrencyCell,
          ExcelTemplate.CURRENCY
        );
        //#endregion
        //#region Portfolio Grand Total
        const pgt = Object.create({});
        pgt.projectName = 'Portfolio Grand Total';
        pgt.totalAmountFinanced = x.portfolioTotalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(x.portfolioTotalAmountFinanced)
          : 0;
        pgt.totalRemainingBalance = x.portfolioTotalRemainingBalance
          ? this.exportUtils.formatNumberToFixed(
              x.portfolioTotalRemainingBalance
            )
          : 0;

        const portfolioGrandTotalRow = worksheet.addRow(pgt);

        portfolioGrandTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          portfolioGrandTotalRow,
          specificCurrencyCell,
          ExcelTemplate.CURRENCY
        );
        //#endregion
        //#region Grand Total
        const ppgt = Object.create({});
        ppgt.projectName = '% Portfolio Grand Total';
        ppgt.totalAmountFinanced = x.percentPortfolioTotalAmountFinanced
          ? this.exportUtils.formatNumberToFixed(
              x.percentPortfolioTotalAmountFinanced
            )
          : 0;
        ppgt.totalRemainingBalance = x.percentPortfolioTotalRemainingBalance
          ? this.exportUtils.formatNumberToFixed(
              x.percentPortfolioTotalRemainingBalance
            )
          : 0;

        const percentPortfolioGrandTotalRow = worksheet.addRow(ppgt);

        percentPortfolioGrandTotalRow.font = { size: 11, bold: true };
        this.formatSpecificCell(
          percentPortfolioGrandTotalRow,
          specificRateCell,
          ExcelTemplate.RATE
        );

        worksheet.addRow();
        //#endregion
      });

    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);

    // Two columns are frozen at the left of the worksheet
    worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 4 }];

    // Set Arial as font
    worksheet.eachRow({ includeEmpty: true }, (row: any) => {
      row.font = { ...row.font, name: 'Arial' };
    });

    return worksheet;
  }

  convertToAssessmentBillingTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    headerRow.fill = ExcelTemplate.GREY_FILL;

    const dateColumn = ['D', 'E'];
    const leftAlignColumn = ['A', 'B', 'C'];
    const wrapTextColumn = ['A', 'F'];
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(
      worksheet,
      leftAlignColumn,
      ExcelTemplate.LEFT_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      wrapTextColumn,
      ExcelTemplate.WRAP_TEXT
    );

    // Always format header row alignment after formatting specific columns
    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    const portfolioList = new Set(
      data.map((x) => x.portfolioCode).filter((x) => x)
    );
    const dataList: any = [];
    portfolioList.forEach((x) => {
      dataList.push({ portfolioCode: x, project: [] });
    });

    dataList.map((x: any) => {
      x.project = data.filter((y) => y.portfolioCode == x.portfolioCode);
      x.projectName = x.portfolioCode;
      const row = worksheet.addRow(x);
      worksheet.mergeCells(`A${row.number}:Q${row.number}`);
      row.font = { size: 11, bold: true };
      x.project.map((y: any) => {
        this.paymentTaskTemplate(worksheet, y);
      });
      worksheet.addRow();
    });

    data.forEach((x) => {
      if (this.checkCertificationOrBillingTask(x.caseType)) {
        this.certificationOrBillingTaskTemplate(worksheet, x);
      }
    });

    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);
    return worksheet;
  }
  checkCertificationOrBillingTask = (checkType: any) => {
    return (
      this.utils.compareString(
        checkType,
        SERVICING_TYPES.ASSESSMENT_BILLING.BILLING
      ) ||
      this.utils.compareString(
        checkType,
        SERVICING_TYPES.ASSESSMENT_BILLING.CERTIFICATION
      )
    );
  };
  certificationOrBillingTaskTemplate = (worksheet: any, data: any) => {
    const specificBoldCell = ['A'];
    let taskDetails = {
      projectName: `Program: ${data.programName}`,
      caseType: data.caseType,
      dueDate: data.dueDate,
      closeDate: data.closeDate ? data.closeDate : 'N/A',
      caseNotes: this.formattedNotes([
        { note: data.notes ? data.notes : 'N/A' },
      ]),
    };
    const row = worksheet.addRow(taskDetails);
    this.formatSpecificCell(row, specificBoldCell, ExcelTemplate.BOLD);
    data.projectList &&
      data.projectList.map((x: any) => {
        let projectDetails = {
          projectName: x.projectName ? x.projectName : 'N/A',
          portfolioName: x.portfolioName ? x.portfolioName : 'N/A',
        };
        worksheet.addRow(projectDetails);
      });
    worksheet.addRow();
    return worksheet;
  };
  paymentTaskTemplate = (worksheet: any, data: any) => {
    let taskDetails = {
      projectName: data.projectName ? data.projectName : 'N/A',
      portfolioName: data.portfolioName ? data.portfolioName : 'N/A',
      caseType: data.caseType,
      dueDate: data.dueDate ? data.dueDate : 'N/A',
      caseNotes: this.formattedNotes([
        { note: data.notes ? data.notes : 'N/A' },
      ]),
    };
    worksheet.addRow(taskDetails);
    return worksheet;
  };

  convertToLenderDatabaseTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.spliceRows(0, 3);
    headerRow.fill = ExcelTemplate.LIGHT_BLUE_FILL;

    const dateColumn = ['B'];
    const currencyColumn = ['M', 'N'];
    const leftAlignColumn = ['A', 'D', 'J', 'I', 'L', 'O', 'P', 'Q'];
    const centerAlignColumn = ['C', 'F', 'H', 'G', 'K', 'E'];
    const wrapTextColumn = ['A', 'D', 'I', 'J', 'L', 'P', 'Q'];
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(
      worksheet,
      leftAlignColumn,
      ExcelTemplate.LEFT_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      centerAlignColumn,
      ExcelTemplate.CENTER_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      wrapTextColumn,
      ExcelTemplate.WRAP_TEXT
    );

    // Always format header row alignment after formatting specific columns
    headerRow.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    data.forEach((lender, i) => {
      lender.loanSizeMin =
        lender.loanSizeMin || lender.loanSizeMin == 0
          ? this.exportUtils.formatNumberToFixed(Number(lender.loanSizeMin))
          : null;
      lender.loanSizeMax =
        lender.loanSizeMax || lender.loanSizeMax == 0
          ? this.exportUtils.formatNumberToFixed(Number(lender.loanSizeMax))
          : null;
      lender.projectCount =
        lender.projectCount || lender.projectCount == 0
          ? Number(lender.projectCount)
          : null;
      lender.notes = this.formattedNotes([{ note: lender.notes }]);
      lender.consentNotes = this.formattedNotes([
        { note: lender.consentNotes },
      ]);
      lender.contactDetails = '';
      for (const innerData in lender.contacts) {
        lender.contactDetails += `<strong>${this.exportUtils.getText(
          innerData,
          lender.contacts[innerData],
          'lender-database'
        )}:</strong>\n${this.exportUtils.getValue(
          innerData,
          lender.contacts[innerData]
        )}\n`;
      }
      lender.contactDetails = this.formattedNotes([
        { note: lender.contactDetails },
      ]);
      lender.sendProjects =
        lender.sendProjects !== null
          ? this.utils.formatBooleanToYesOrNo(lender.sendProjects, 'YES_NO')
          : null;
      lender.willingToConsent =
        lender.willingToConsent !== null
          ? this.utils.formatBooleanToYesOrNo(lender.willingToConsent, 'YES_NO')
          : null;
      lender.openToCollaboration =
        lender.openToCollaboration !== null
          ? this.utils.formatBooleanToYesOrNo(
              lender.openToCollaboration,
              'YES_NO'
            )
          : null;
      lender.propertyType = lender.propertyType
        ? lender.propertyType.join(', ')
        : null;
      lender.lenderType = lender.lenderType
        ? lender.lenderType.join(', ')
        : null;
      lender.loanType = lender.loanType ? lender.loanType.join(', ') : null;
      lender.statesLendIn = lender.statesLendIn
        ? lender.statesLendIn.join(', ')
        : null;
      worksheet.addRow(lender);
    });

    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);

    // Set Arial as font
    worksheet.eachRow({ includeEmpty: true }, (row: any) => {
      row.font = { ...row.font, name: 'Arial' };
    });

    return worksheet;
  }
  convertToTrancheReportTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.getRow(1).height = 100;
    headerRow.fill = ExcelTemplate.GREY_FILL;
    const dateColumn = ['C', 'D'];
    const currencyColumn = ['E', 'F', 'G', 'H', 'I', 'J'];
    const leftAlignColumn = ['A', 'B'];
    const wrapTextColumn = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(
      worksheet,
      leftAlignColumn,
      ExcelTemplate.LEFT_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      wrapTextColumn,
      ExcelTemplate.WRAP_TEXT
    );

    data
      .filter((x) => x.portfolioId)
      .map((x) => {
        //Row for portfolio name
        if (x.showName) {
          const portfolioName = Object.create({});
          portfolioName.projectName = x.portfolioName;
          const portfolioNameRow = worksheet.addRow(portfolioName);
          worksheet.mergeCells(
            `A${portfolioNameRow.number}:J${portfolioNameRow.number}`
          );
          portfolioNameRow.font = { size: 11, bold: true };
        }
        // warehouse start
        const whi = Object.create({});
        whi.projectName = `Warehouse Interest`;
        const warehouseInterestRow = worksheet.addRow(whi);
        worksheet.mergeCells(
          `A${warehouseInterestRow.number}:J${warehouseInterestRow.number}`
        );
        warehouseInterestRow.font = { size: 11, bold: true };
        //#endregion
        x.warehouseInterest.projects.map((y: any) => {
          y.beginningCapIBalance = y.beginningCapIBalance
            ? this.exportUtils.formatNumberToFixed(y.beginningCapIBalance)
            : 0;
          y.pehInterest = y.pehInterest
            ? this.exportUtils.formatNumberToFixed(y.pehInterest)
            : 0;
          y.peServicing = y.peServicing
            ? this.exportUtils.formatNumberToFixed(y.peServicing)
            : 0;
          y.adminInterest = y.adminInterest
            ? this.exportUtils.formatNumberToFixed(y.adminInterest)
            : 0;
          y.totalCapIPeriod = y.totalCapIPeriod
            ? this.exportUtils.formatNumberToFixed(y.totalCapIPeriod)
            : 0;
          y.endingBalance = y.endingBalance
            ? this.exportUtils.formatNumberToFixed(y.endingBalance)
            : 0;
          const row = worksheet.addRow(y);
        });
        // #region warehousetotal
        const warehouseTotal = Object.create({});
        warehouseTotal.projectName = `Total Warehouse Interest`;
        warehouseTotal.beginningCapIBalance =
          x.totalWarehouseBeginningCapIBalance
            ? this.exportUtils.formatNumberToFixed(
                x.totalWarehouseBeginningCapIBalance
              )
            : 0;
        warehouseTotal.pehInterest = x.totalWarehousePehInterest
          ? this.exportUtils.formatNumberToFixed(x.totalWarehousePehInterest)
          : 0;
        warehouseTotal.peServicing = x.totalWarehousePaceEquityServicing
          ? this.exportUtils.formatNumberToFixed(
              x.totalWarehousePaceEquityServicing
            )
          : 0;
        warehouseTotal.adminInterest = x.totalWarehouseAdminInterest
          ? this.exportUtils.formatNumberToFixed(x.totalWarehouseAdminInterest)
          : 0;
        warehouseTotal.totalCapIPeriod = x.totalWarehouseCapIPeriod
          ? this.exportUtils.formatNumberToFixed(x.totalWarehouseCapIPeriod)
          : 0;
        warehouseTotal.endingBalance = x.totalWarehouseEndingBalance
          ? this.exportUtils.formatNumberToFixed(x.totalWarehouseEndingBalance)
          : 0;

        const warehouseTotalRow = worksheet.addRow(warehouseTotal);
        warehouseTotalRow.font = { size: 11, bold: true };
        worksheet.addRow();
        // #endregion warehouse end

        // securitization
        const secI = Object.create({});
        secI.projectName = `Securitized Interest`;
        const securitizedInterestRow = worksheet.addRow(secI);
        worksheet.mergeCells(
          `A${securitizedInterestRow.number}:J${securitizedInterestRow.number}`
        );
        securitizedInterestRow.font = { size: 11, bold: true };

        x.securitizationInterest.projects.map((y: any) => {
          y.beginningCapIBalance = y.beginningCapIBalance
            ? this.exportUtils.formatNumberToFixed(y.beginningCapIBalance)
            : 0;
          y.pehInterest = y.pehInterest
            ? this.exportUtils.formatNumberToFixed(y.pehInterest)
            : 0;
          y.peServicing = y.peServicing
            ? this.exportUtils.formatNumberToFixed(y.peServicing)
            : 0;
          y.adminInterest = y.adminInterest
            ? this.exportUtils.formatNumberToFixed(y.adminInterest)
            : 0;
          y.totalCapIPeriod = y.totalCapIPeriod
            ? this.exportUtils.formatNumberToFixed(y.totalCapIPeriod)
            : 0;
          y.endingBalance = y.endingBalance
            ? this.exportUtils.formatNumberToFixed(y.endingBalance)
            : 0;
          const row = worksheet.addRow(y);
        });

        const securitizationTotal = Object.create({});
        securitizationTotal.projectName = `Total Securitized Interest`;
        securitizationTotal.beginningCapIBalance = x.totalBeginningCapIBalance
          ? this.exportUtils.formatNumberToFixed(x.totalBeginningCapIBalance)
          : 0;
        securitizationTotal.pehInterest = x.totalPehInterest
          ? this.exportUtils.formatNumberToFixed(x.totalPehInterest)
          : 0;
        securitizationTotal.peServicing = x.totalPaceEquityServicing
          ? this.exportUtils.formatNumberToFixed(x.totalPaceEquityServicing)
          : 0;
        securitizationTotal.adminInterest = x.totalAdminInterest
          ? this.exportUtils.formatNumberToFixed(x.totalAdminInterest)
          : 0;
        securitizationTotal.totalCapIPeriod = x.totalCapIPeriod
          ? this.exportUtils.formatNumberToFixed(x.totalCapIPeriod)
          : 0;
        securitizationTotal.endingBalance = x.totalEndingBalance
          ? this.exportUtils.formatNumberToFixed(x.totalEndingBalance)
          : 0;
        const securitizationTotalRow = worksheet.addRow(securitizationTotal);
        securitizationTotalRow.font = { size: 11, bold: true };
        worksheet.mergeCells(
          `A${securitizationTotalRow.number}:C${securitizationTotalRow.number}`
        );

        worksheet.addRow();
      });

    data
      .filter((x) => !x.portfolioId)
      .map((x) => {
        const grandTotal = Object.create({});
        grandTotal.projectName = `Grand Total`;
        grandTotal.beginningCapIBalance = x.grandTotalBeginningCapIBalance
          ? this.exportUtils.formatNumberToFixed(
              x.grandTotalBeginningCapIBalance
            )
          : 0;
        grandTotal.pehInterest = x.grandTotalPehInterest
          ? this.exportUtils.formatNumberToFixed(x.grandTotalPehInterest)
          : 0;
        grandTotal.peServicing = x.grandTotalPaceEquityServicing
          ? this.exportUtils.formatNumberToFixed(
              x.grandTotalPaceEquityServicing
            )
          : 0;
        grandTotal.adminInterest = x.grandTotalAdminInterest
          ? this.exportUtils.formatNumberToFixed(x.grandTotalAdminInterest)
          : 0;
        grandTotal.totalCapIPeriod = x.grandTotalCapIPeriod
          ? this.exportUtils.formatNumberToFixed(x.grandTotalCapIPeriod)
          : 0;
        grandTotal.endingBalance = x.grandTotalEndingBalance
          ? this.exportUtils.formatNumberToFixed(x.grandTotalEndingBalance)
          : 0;
        const grandTotalRow = worksheet.addRow(grandTotal);
        grandTotalRow.font = { size: 11, bold: true };
        worksheet.addRow();
      });
    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);
    // Two columns are frozen at the left of the worksheet
    worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 4 }];

    // Set Arial as font
    worksheet.eachRow({ includeEmpty: true }, (row: any) => {
      row.font = { ...row.font, name: 'Arial' };
    });

    return worksheet;
  }

  convertToPrepaymentDetailTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.getRow(1).height = 100;
    headerRow.fill = ExcelTemplate.GREY_FILL;
    const dateColumn = ['C', 'F'];
    const currencyColumn = ['G'];
    const leftAlignColumn = ['A', 'B'];
    const percentColumn = ['H'];
    const durationColumn = ['I'];
    const wrapTextColumn = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(
      worksheet,
      leftAlignColumn,
      ExcelTemplate.LEFT_ALIGN
    );
    this.formatSpecificColumns(
      worksheet,
      wrapTextColumn,
      ExcelTemplate.WRAP_TEXT
    );
    this.formatSpecificColumns(worksheet, percentColumn, ExcelTemplate.RATE);
    this.formatSpecificColumns(worksheet, durationColumn, ExcelTemplate.NUMBER);
    data
      .filter((y) => y.portfolio)
      .map((x) => {
        const portfolioDetail = Object.create({});
        portfolioDetail.portfolio = x.portfolio ? x.portfolio : 'N/A';
        portfolioDetail.project = x.project ? x.project : 'N/A';
        portfolioDetail.closedDate = x.closedDate ? x.closedDate : 'N/A';
        portfolioDetail.projectType = x.projectType ? x.projectType : 'N/A';
        portfolioDetail.propertyType = x.propertyType ? x.propertyType : 'N/A';
        portfolioDetail.prepaymentDate = x.prepaymentDate
          ? x.prepaymentDate
          : 'N/A';
        portfolioDetail.currentBalance = x.currentBalance
          ? this.exportUtils.formatNumberToFixed(x.currentBalance)
          : 0;
        portfolioDetail.prepayPercent = x.prepayPercent
          ? this.exportUtils.formatNumberToFixed(x.prepayPercent)
          : 0;
        portfolioDetail.duration = x.duration
          ? this.exportUtils.formatNumberToFixed(x.duration)
          : 0;
        worksheet.addRow(portfolioDetail);
      });

    data
      .filter((x) => !x.portfolio)
      .map((y) => {
        const portfolioDetailTotal = Object.create({});
        portfolioDetailTotal.portfolio = 'Total';
        portfolioDetailTotal.currentBalance = y.currentBalance
          ? this.exportUtils.formatNumberToFixed(y.currentBalance)
          : 0;

        const portfolioDetailTotalRow = worksheet.addRow(portfolioDetailTotal);
        portfolioDetailTotalRow.font = { size: 11, bold: true };
        worksheet.mergeCells(
          `A${portfolioDetailTotalRow.number}:E${portfolioDetailTotalRow.number}`
        );
      });
    worksheet.views = [
      { state: 'frozen', ySplit: 4 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];

    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);
    // Two columns are frozen at the left of the worksheet
    // worksheet.views = [{state: 'frozen', xSplit: 1, ySplit: 4}];

    // Set Arial as font
    worksheet.eachRow({ includeEmpty: true }, (row: any) => {
      row.font = { ...row.font, name: 'Arial' };
    });

    return worksheet;
  }
  convertToPrepaymentSummaryTemplate(
    worksheet: any,
    data: any[],
    headerRow: any,
    bodyTemplate: any
  ) {
    worksheet.getRow(1).height = 100;
    headerRow.fill = ExcelTemplate.GREY_FILL;
    const dateColumn = ['A'];
    const currencyColumn = ['B', 'C', 'D', 'E', 'F'];
    const percentColumn = ['G'];
    this.formatSpecificColumns(worksheet, dateColumn, ExcelTemplate.DATE);
    this.formatSpecificColumns(
      worksheet,
      currencyColumn,
      ExcelTemplate.CURRENCY
    );
    this.formatSpecificColumns(worksheet, percentColumn, ExcelTemplate.RATE);

    data
      .filter((y) => y.year)
      .map((x) => {
        const portfolioSummary = Object.create({});
        portfolioSummary.year = x.year ? x.year : 'N/A';
        portfolioSummary.beginningBalance = x.beginningBalance
          ? this.exportUtils.formatNumberToFixed(x.beginningBalance)
          : 0;
        portfolioSummary.newOrigination = x.newOrigination
          ? this.exportUtils.formatNumberToFixed(x.newOrigination)
          : 0;
        portfolioSummary.principalPayments = x.principalPayments
          ? this.exportUtils.formatNumberToFixed(x.principalPayments)
          : 0;
        portfolioSummary.prepay = x.prepay
          ? this.exportUtils.formatNumberToFixed(x.prepay)
          : 0;
        portfolioSummary.endingBalance = x.endingBalance
          ? this.exportUtils.formatNumberToFixed(x.endingBalance)
          : 0;
        portfolioSummary.prepayPercentByYear = x.prepayPercentByYear
          ? this.exportUtils.formatNumberToFixed(x.prepayPercentByYear)
          : 0;
        worksheet.addRow(portfolioSummary);
      });
    data
      .filter((x) => !x.year)
      .map((y) => {
        const portfolioSummaryTotal = Object.create({});
        portfolioSummaryTotal.year = 'Total';
        portfolioSummaryTotal.newOrigination = y.newOrigination
          ? this.exportUtils.formatNumberToFixed(y.newOrigination)
          : 0;
        portfolioSummaryTotal.principalPayments = y.principalPayments
          ? this.exportUtils.formatNumberToFixed(y.principalPayments)
          : 0;
        portfolioSummaryTotal.prepay = y.prepay
          ? this.exportUtils.formatNumberToFixed(y.prepay)
          : 0;
        portfolioSummaryTotal.prepayPercentByYear = y.prepayPercentByYear
          ? this.exportUtils.formatNumberToFixed(y.prepayPercentByYear)
          : 0;
        const portfolioDetailTotalRow = worksheet.addRow(portfolioSummaryTotal);
        portfolioDetailTotalRow.font = { size: 11, bold: true };
      });
    // Generate min width for specific
    this.generateMinWidth(worksheet, bodyTemplate);
    worksheet.views = [
      { state: 'frozen', ySplit: 4 }, // xSplit: How many columns to freeze, ySplit: How many rows to freeze.
    ];

    // Set Arial as font
    worksheet.eachRow({ includeEmpty: true }, (row: any) => {
      row.font = { ...row.font, name: 'Arial' };
    });

    return worksheet;
  }

  formattedNotes(notes: any) {
    const formattedString = notes?.map((data: any) => {
      return data?.note
        ?.replaceAll('</li>', '</li> \n')
        .replaceAll('<br />', '\n');
    });

    return formattedString.join('').replace(/<[^>]*>/g, '');
  }
  formatSpecificCell = (row: any, formatCells: any = [], format: any) => {
    switch (format) {
      case ExcelTemplate.CURRENCY:
        formatCells.map((cell: any) => {
          row.getCell(cell).numFmt = ExcelTemplate.CURRENCY;
          row.getCell(cell).alignment = {
            ...row.getCell(cell).alignment,
            horizontal: 'right',
          };
        });
        break;
      case ExcelTemplate.RATE:
        formatCells.map((cell: any) => {
          row.getCell(cell).numFmt = ExcelTemplate.RATE;
          row.getCell(cell).alignment = {
            ...row.getCell(cell).alignment,
            horizontal: 'right',
          };
        });
        break;
      case ExcelTemplate.BOLD:
        formatCells.map((cell: any) => {
          row.getCell(cell).font = {
            ...row.getCell(cell).font,
            ...format,
          };
        });
        break;
      case ExcelTemplate.WRAP_TEXT:
        formatCells.map((cell: any) => {
          row.getCell(cell).alignment = {
            ...row.getCell(cell).alignment,
            wrapText: true,
          };
        });
        break;
    }
  };
  formatSpecificColumns = (
    worksheet: any,
    formatColumns: any = [],
    format: any
  ) => {
    switch (format) {
      case ExcelTemplate.DATE:
        formatColumns.map((col: any) => {
          worksheet.getColumn(col).alignment = {
            ...worksheet.getColumn(col).alignment,
            horizontal: 'center',
          };
        });
        break;
      case ExcelTemplate.CURRENCY:
        formatColumns.map((col: any) => {
          worksheet.getColumn(col).numFmt = ExcelTemplate.CURRENCY;
          worksheet.getColumn(col).alignment = {
            ...worksheet.getColumn(col).alignment,
            horizontal: 'right',
          };
        });
        break;
      case ExcelTemplate.RATE:
        formatColumns.map((col: any) => {
          worksheet.getColumn(col).numFmt = ExcelTemplate.RATE;
          worksheet.getColumn(col).alignment = {
            ...worksheet.getColumn(col).alignment,
            horizontal: 'right',
          };
        });
        break;
      case ExcelTemplate.LEFT_ALIGN:
        formatColumns.map((col: any) => {
          worksheet.getColumn(col).alignment = {
            ...worksheet.getColumn(col).alignment,
            horizontal: 'left',
          };
        });
        break;
      case ExcelTemplate.CENTER_ALIGN:
        formatColumns.map((col: any) => {
          worksheet.getColumn(col).alignment = {
            ...worksheet.getColumn(col).alignment,
            horizontal: 'center',
          };
        });
        break;
      case ExcelTemplate.WRAP_TEXT:
        formatColumns.map((col: any) => {
          worksheet.getColumn(col).alignment = {
            ...worksheet.getColumn(col).alignment,
            wrapText: true,
          };
        });
        break;
      case ExcelTemplate.NUMBER:
        formatColumns.map((col: any) => {
          worksheet.getColumn(col).numFmt = ExcelTemplate.NUMBER;
          worksheet.getColumn(col).alignment = {
            ...worksheet.getColumn(col).alignment,
            horizontal: 'center',
          };
        });
        break;
    }
  };

  roundUpOldMethod(data: any) {
    let newData = Object.entries(data).reduce(
      (p, [key, value]) => ({
        ...p,
        [key]:
          typeof value === 'number'
            ? this.exportUtils.formatNumberToFixed(value)
            : value,
      }),
      {}
    );
    return newData;
  }

  setCellProperties(cell: any, value: any, isBold: boolean) {
    cell.alignment = { horizontal: 'left', wrapText: true };
    cell.font = { size: 11, bold: isBold };
    cell.value = value;
  }
}
