import { Injectable } from '@angular/core';
import { PaceEquityUtils } from './pace-equity.utils';
import { ToasterService } from '../services';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AssessmentUtils {
  legalAndClosingExpensesOverriddenMessage =
    'Please provide a reason for updating the Legal & Closing Expenses amount';
  constructor(
    private toastService: ToasterService,
    public paceEquityUtils: PaceEquityUtils
  ) {}

  public setSelectedProgramDataForAssessment = (
    selectedProgram: any,
    {
      assessmentDate = {},
      assessmentAdministrationFee = {},
      assessmentClosingAdminFees = [],
    }: any = {}
  ) => {
    const assessment = {
      paymentsPerYear: selectedProgram.paymentsPerYear,
      firstPaymentMonthDay: selectedProgram.firstTaxPaymentMonthDay,
      secondPaymentMonthDay: selectedProgram.secondTaxPaymentMonthDay,
      firstSecuredPaymentMonthDay: selectedProgram.firstSecuredPaymentMonthDay,
      secondSecuredPaymentMonthDay:
        selectedProgram.secondSecuredPaymentMonthDay,
      isSinglePrincipalPayment: selectedProgram.isSinglePrincipalPayment,
      oneTimeFeeDescription: selectedProgram.oneTimeFeeDescription,
      assessmentDate: {
        ...assessmentDate,
        amortizationStartDate: selectedProgram.amortizationStartMonthDay,
        paymentStartDate: selectedProgram.amortizationStartMonthDay,
      },
      assessmentAdministrationFee: {
        ...assessmentAdministrationFee,
        percentageAddedToInterestRate:
          selectedProgram.percentageAddedToInterestRate,
        percentageBegPrincipalBalanceFee:
          selectedProgram.percentageBegPrincipalBalanceFee,
        percentageAmtFunded: selectedProgram.percentageAmtFunded,
        percentagePiPayment: selectedProgram.percentagePiPayment,
        isPercentageOfAnnualAssessment:
          selectedProgram.isPercentageOfAnnualAssessment,
        fixedAmount: selectedProgram.fixedAmount,
        firstPaymentOveride: selectedProgram.firstPaymentOveride,
        min: selectedProgram.min,
        max: selectedProgram.max,
        isAdminInterest: selectedProgram.isAdminInterest,
      },
      assessmentClosingAdminFees: [
        ...assessmentClosingAdminFees.map((x: any) => {
          x.isActive = false;
          return x;
        }),
        ...selectedProgram.closingAdminFees.map((x: any) => {
          x.isActive = true;
          delete x.id;
          return x;
        }),
      ],
    };

    return assessment;
  };

  public setupAssessmentDataForEdit = (assessment: any) => {
    const editAssessmentModel = JSON.parse(JSON.stringify(assessment)); // removes binding of the variables

    let amortdDate = editAssessmentModel.assessmentDate?.amortizationStartDate;
    editAssessmentModel.assessmentDate.amortizationStartDate =
      amortdDate?.split('/').length > 2
        ? amortdDate.substring(0, amortdDate.lastIndexOf('/'))
        : amortdDate;
    editAssessmentModel.assessmentSweepDates.map((x: any) => {
      x.isValid = true;
    });
    editAssessmentModel.interestAmount = editAssessmentModel.isCapiOverride
      ? editAssessmentModel.interestAmount
      : null;
    editAssessmentModel.piPayment = editAssessmentModel.isManualPIPayment
      ? editAssessmentModel.piPayment
      : null;
    editAssessmentModel.piPaymentPublished =
      editAssessmentModel.isManualPIPaymentPublished
        ? editAssessmentModel.piPaymentPublished
        : null;
    editAssessmentModel.financedAmount =
      editAssessmentModel.isManualFinancedAmount
        ? editAssessmentModel.financedAmount
        : null;
    editAssessmentModel.assessmentDate.maturityDate =
      editAssessmentModel.isMaturityDateOverride ||
      editAssessmentModel.isManualPaymentSchedule
        ? editAssessmentModel.assessmentDate.maturityDate
        : null;
    editAssessmentModel.assessmentDate.closedDate = editAssessmentModel
      .assessmentDate.closedDate
      ? new Date(editAssessmentModel.assessmentDate.closedDate)
      : null;
    editAssessmentModel.assessmentDate.fundingDate = editAssessmentModel
      .assessmentDate.fundingDate
      ? new Date(editAssessmentModel.assessmentDate.fundingDate)
      : null;
    //If Funding Date and Closed Date both are same, then no need to display Funding Date value
    if (
      editAssessmentModel.assessmentDate.fundingDate ==
      editAssessmentModel.assessmentDate.closedDate
    )
      editAssessmentModel.assessmentDate.fundingDate = null;

    return editAssessmentModel;
  };

  validateInterestOnlyPayment(assessment: any, showToastMessage: boolean) {
    if (assessment.hasIop) {
      if (!assessment.iopYears) {
        if (showToastMessage) {
          this.toastService.warning(
            'Interest-Only Payment Terms should not be zero or empty'
          );
        }
        return false;
      }

      if (Number(assessment.contractYears) <= Number(assessment.iopYears)) {
        if (showToastMessage) {
          this.toastService.warning(
            'Interest-Only Payment Terms should be less than Repayment Term * Payments Per Year.'
          );
        }
        return false;
      }
    }
    return true;
  }

  calculateAmortizationStartDate(assessment: any) {
    if (
      assessment.assessmentDate.paymentStartDate &&
      this.paceEquityUtils.checkIfValidDate(
        assessment.assessmentDate.paymentStartDate,
        'MM/DD/YYYY',
        true
      )
    ) {
      // make sure this date is always in mm/dd
      assessment.assessmentDate.paymentStartDate = moment(
        assessment.assessmentDate.paymentStartDate,
        'MM/DD/YYYY'
      ).format('MM/DD');
    }
    let isValid =
      assessment.hasIop &&
      this.validateInterestOnlyPayment(assessment, false) &&
      assessment.paymentsPerYear &&
      this.paceEquityUtils.checkIfValidDateOfMonthAndDay(
        assessment.firstPaymentMonthDay,
        'MM/DD',
        true
      ) &&
      this.paceEquityUtils.checkIfValidDateOfMonthAndDay(
        assessment.assessmentDate.paymentStartDate,
        'MM/DD',
        true
      ) &&
      this.paceEquityUtils.checkIfValidDate(
        assessment.firstPaymentYear,
        'YYYY',
        true
      );
    let amortizationDate;
    if (isValid) {
      let yearWithIop =
        Number(assessment.firstPaymentYear) + Number(assessment.iopYears);

      yearWithIop -=
        new Date(assessment.assessmentDate.paymentStartDate) <=
        new Date(assessment.firstPaymentMonthDay)
          ? 0
          : 1;

      amortizationDate =
        assessment.assessmentDate.paymentStartDate + '/' + yearWithIop;
      return amortizationDate;
    } else {
      return null;
    }
  }
}
