<div mdbDropdown class="dropdown" #dropdown [closeOnOutsideClick]="false">
  <button
    type="button"
    class="btn btn-light"
    id="dropdownMenuButton"
    mdbDropdownToggle
    >
    <!-- (click)="fetchRequiredData()" -->
    <i class="fas fa-sliders-h"></i>
  </button>
  <div
    mdbDropdownMenu
    class="dropdown-menu dropdown-menu-end"
    [ngClass]="
      type == ADVANCED_FILTER_TYPES.PROGRAM
        ? 'program-filter'
        : 'project-filter'
    "
    aria-labelledby="dropdownMenuButton"
    >
    <div class="d-flex justify-content-between">
      <h5 class="ms-2 font-weight-400">Advanced Filter</h5>
      <button
        type="button"
        class="btn-close"
        (click)="dropdown.hide()"
      ></button>
    </div>
    <div class="divider dropdown-divider"></div>
    <div>
      @switch (type) {
        @case (ADVANCED_FILTER_TYPES.PROJECT) {
          <app-project-filter
            #projectFilter
            [filterPropertyType]="propertyTypeList"
            [advanceFilter]="advanceFilter"
          ></app-project-filter>
        }
        @case (ADVANCED_FILTER_TYPES.DASHBOARD) {
          <app-dashboard-filter
            [filterPropertyType]="propertyTypeList"
            #dashboardFilter
          ></app-dashboard-filter>
        }
        <!-- <app-assessment-billing-task-filter
        *ngSwitchCase="ADVANCED_FILTER_TYPES.ASSESSMENT_BILLING_TASK"
      #assessmentBillingTaskFilter></app-assessment-billing-task-filter> -->
      @case (ADVANCED_FILTER_TYPES.STATE_DATABASE) {
        <app-state-database-filter
          #stateDatabaseFilter
        ></app-state-database-filter>
      }
      @case (ADVANCED_FILTER_TYPES.PROGRAM) {
        <app-program-filter
          #programFilter
        ></app-program-filter>
      }
      <!-- <app-lender-database-filter
      [lenderList]="lenderList"
      #lenderDatabaseFilter
    *ngSwitchCase="ADVANCED_FILTER_TYPES.LENDER_DATABASE"></app-lender-database-filter> -->
    @case (ADVANCED_FILTER_TYPES.LENDER_DATABASE) {
      <app-cre-capital-database-filter
        [filterPropertyType]="propertyTypeList"
        #creDatabaseFilter
      ></app-cre-capital-database-filter>
    }
  }
</div>
<div class="divider dropdown-divider"></div>
<div class="d-flex justify-content-between">
  <button
    type="button"
    class="btn btn-light waves-light z-depth-0 m-0"
    mdbWavesEffect
    (click)="resetFilter()"
    >
    <i class="fas fa-redo"></i> Reset
  </button>
  <button
    type="button"
    class="btn btn-secondary waves-light z-depth-0 m-0"
    mdbWavesEffect
    (click)="submitAdvanceFilter(); dropdown.hide()"
    >
    <i class="fas fa-check-circle"></i> Apply
  </button>
</div>
</div>
</div>
