import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { MDBAngularModule } from "./mdb-angular.module";

import * as guards from '../guards';
import { NgxModule } from "./ngx-module";
import { FileUploadModule } from "@iplab/ngx-file-upload";

@NgModule({ declarations: [],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MDBAngularModule,
        NgxModule,
        CdkScrollableModule,
        FileUploadModule
    ], imports: [
        FormsModule,
        ReactiveFormsModule,
        MDBAngularModule,
        NgxModule,
        CdkScrollableModule,
        FileUploadModule], providers: [
        guards.AuthGuard,
        guards.RoleGuard,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class SharedModule { }