import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService, UserService } from '../services';
import { PaceEquityUtils } from '../utils';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private isAuthUserVerifying = false;
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor(
    private authService: AuthService,
    private paceEquityUtils: PaceEquityUtils,
    private userService: UserService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const needReloadHeader = event.headers.get('Need-Reload');
          if (needReloadHeader) {
            const reloadData = JSON.parse(needReloadHeader);
            if (reloadData.forProforma) {
              if (!this.isAuthUserVerifying) {
                this.isAuthUserVerifying = true;
                this.tokenSubject.next(null); // Reset the token subject

                this.authService.verifyAuthUser().subscribe({
                  next: (response: any) => {
                    if (response && response.success && response.data) {
                      this.paceEquityUtils.setLoggedInUser(response.data);
                      this.userService.checkUserPermission().subscribe({
                        next: (x) => {
                          this.continue(x);
                          if (x && x.success && x.data)
                            this.paceEquityUtils.setPermissionAccess(x.data);
                        },
                        error: (err) => this.continue(err),
                      });
                    }
                  },
                  error: (err) => this.continue(err),
                });
              } else {
                this.tokenSubject.pipe(
                  filter((token) => token !== null),
                  take(1),
                  switchMap((newToken: any) => {
                    // Retry the original request with the new token
                    return next.handle(req);
                  })
                );
              }
            }
          }
        }
      })
    );
  }

  continue(res: any) {
    this.isAuthUserVerifying = false;
    this.tokenSubject.next(res);
  }
}
