import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, retry, filter, take } from 'rxjs/operators';
import { PaceEquityUtils } from '../utils';
// import { ToastService } from 'ng-uikit-pro-standard';
import { AuthService, ToasterService } from '../services';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isLoggingOut: boolean = false;
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);

  constructor(
    public _paceEquityUtils: PaceEquityUtils,
    private _toasterService: ToasterService,
    private _authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // IF FORBIDDEN FROM s3 BUCKET DO NOT LOGOUT
    if (req?.url?.includes('https://paceequitygallery.s3.us-east-2.amazonaws.com/')) {
      return next.handle(req.clone({ withCredentials: true }));
    }

    return next.handle(req.clone({ withCredentials: true })).pipe(
      retry(0),
      catchError((x) => this.handleAuthError(req, next, x))
    );
  }

  private handleAuthError(
    req: HttpRequest<any>,
    next: HttpHandler,
    err: HttpErrorResponse | any
  ): Observable<any> {
    if (err && (err.status === 401 || err.status === 403)) {
      const accessModifierMsg =
        'Your access has been modified. Please re-login.';

      if (err?.error?.message === accessModifierMsg) {
        return this.logout(err, accessModifierMsg);
      }
      //ignore /auth-user endpoint
      else if (err.url.includes('/auth-user'))
        return throwError({ isActiveSession: false });
      if (err?.error?.body?.isExpired || err.url.includes('/refresh')) {
        if (!this.isLoggingOut) return this.logout(err);
      }
      let loggedInUser = this._paceEquityUtils.getLoggedInUser();
      if (loggedInUser) {
        if (!this.isRefreshingToken) {
          this.isRefreshingToken = true;
          this.tokenSubject.next(null); // Reset the token subject

          return this._authService.refreshToken().pipe(
            switchMap((newToken: any) => {
              this.isRefreshingToken = false;
              this.tokenSubject.next(newToken);
              return next.handle(req.clone({ withCredentials: true }));
            }),
            catchError((refreshError: any) => {
              this.isRefreshingToken = false;
              return this.logout(refreshError);
            })
          );
        } else {
          // Token refresh is already in progress, queue the request
          return this.tokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((newToken: any) => {
              // Retry the original request with the new token
              return next.handle(req.clone({ withCredentials: true }));
            })
          );
        }
      }

      if (!this.isLoggingOut) return this.logout(err);

      return of();
    }
    return throwError(() => err);
  }

  logout = (err: HttpErrorResponse, logoutMsg?: any) => {
    //Check if the session has been already logged out
    let loggedInUser = this._paceEquityUtils.getLoggedInUser();
    if (!loggedInUser) return of();

    this.isLoggingOut = true;

    const message = logoutMsg
      ? logoutMsg
      : 'Your session has expired. Please login again to continue working.';
    this._authService.logout();
    this._toasterService.error(message);
    this.isLoggingOut = false;
    this.isRefreshingToken = false;
    return of(message);
  };
}
