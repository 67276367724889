import { NgModule } from '@angular/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [],
  imports: [
    BsDatepickerModule.forRoot(),
    QuillModule.forRoot()
  ],
  exports: [BsDatepickerModule, QuillModule],
})
export class NgxModule {}
