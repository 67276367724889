@if (filterList.length) {
  <div class="keywords d-flex">
    <div class="keywords my-1 border-left d-flex align-items-center">
      <small class="me-2 font-weight-500">Filters: </small>
      <div>
        @for (filter of filterList; track filter) {
          <span class="me-1 ">
            <!-- <small class="d-block text-muted ms-1"></small> -->
            <div class="badge rounded-pill bg-primary mb-0">
              {{ filter.field }}:
              <span class="font-weight-500 text-dark me-1">{{ filter.label }}</span>
              <i class="fas fa-times btn-icon" (click)="removeFilter(filter)"></i>
            </div>
          </span>
        }
      </div>
      <button
        type="button"
        class="btn btn-link waves-light m-0 p-0 mb-0 "
        (click)="resetFilter()"
        >
        <span class="text-info text-sm text-nowrap">Reset Filter</span>
      </button>
    </div>
  </div>
}
