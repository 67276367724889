import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { APICONSTANTS } from '../constants';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SizingDashboardService {
  constructor(private http: HttpClientService) {}

  fetchSizingDashboard(projectId: number) {
    return this.http
      .get(`${APICONSTANTS.PROJECT}/${projectId}/sizing-dashboard`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
