import { Injectable } from '@angular/core';
import { PaceEquityUtils } from './pace-equity.utils';
import { DatabaseService } from '../services';
import { GLOBAL } from '../constants';
import { finalize, map } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CapitalStackUtils {
  MODAL = GLOBAL.MODAL;
  OPERATION = GLOBAL.OPERATION;
  lenderList = [];

  constructor(
    private paceEquityUtils: PaceEquityUtils,
    private databaseService: DatabaseService
  ) {}

  splitFundingSources(assetCapitalStackDetail: any) {
    assetCapitalStackDetail = this.subStringName(assetCapitalStackDetail);
    assetCapitalStackDetail.debtFundingSources =
      assetCapitalStackDetail?.fundingSources?.filter((x: any) => x.isDebt);
    assetCapitalStackDetail.equityFundingSources =
      assetCapitalStackDetail?.fundingSources?.filter((x: any) => !x.isDebt);

    return this.calculateTotal(assetCapitalStackDetail);
  }

  subStringName(assetCapitalStackDetail: any) {
    assetCapitalStackDetail.summary.fundingSources =
      assetCapitalStackDetail.summary.fundingSources.map((x: any) => {
        if (x.name) {
          x.shortName = this.paceEquityUtils.limitCharacters(x.name, 20);
        }
        return x;
      });

    return assetCapitalStackDetail;
  }

  calculateTotal(assetCapitalStackDetail: any) {
    let fundingSourceGrandTotal = {
      debtFundingSourceTotal: 0,
      equityFundingSourceTotal: 0,
    };
    let totalAnnualMortgageDebtService: number = 0;
    fundingSourceGrandTotal.debtFundingSourceTotal =
      this.paceEquityUtils.getTotal(
        assetCapitalStackDetail.debtFundingSources,
        'amount'
      );
    fundingSourceGrandTotal.equityFundingSourceTotal =
      this.paceEquityUtils.getTotal(
        assetCapitalStackDetail.equityFundingSources,

        'amount'
      );

    if (assetCapitalStackDetail?.debtFundingSources?.length > 0) {
      assetCapitalStackDetail.debtFundingSources =
        assetCapitalStackDetail.debtFundingSources.map((x: any) => {
          if (x?.assetFundingSourcesCalculation?.annualMortgageDebtService) {
            x.assetFundingSourcesCalculation.annualMortgageDebtService = Number(
              Math.abs(
                x.assetFundingSourcesCalculation.annualMortgageDebtService
              )
            );
          }
          return x;
        });
      totalAnnualMortgageDebtService =
        assetCapitalStackDetail.debtFundingSources
          .map((x: any) =>
            x?.assetFundingSourcesCalculation?.annualMortgageDebtService
              ? Number(
                  x?.assetFundingSourcesCalculation?.annualMortgageDebtService
                )
              : 0
          )
          .reduce((acc: any, val: any) => acc + val, 0);
    }
    return {
      assetCapitalStackDetail,
      fundingSourceGrandTotal,
      totalAnnualMortgageDebtService,
    };
  }

  fetchLenderList() {
    const queryParams = {
      modal: this.MODAL.LENDER,
      operation: this.OPERATION.OPTIONS,
    };

    return this.databaseService.fetchLenderData(queryParams).pipe(
      map((response) => {
        if (
          response &&
          response.success &&
          response.data &&
          response.data.length > 0
        ) {
          return response.data.map((x: any) => {
            x.label = x.name;
            x.value = x.id;
            return x;
          });
        }
        return [];
      })
      // Handle errors gracefully by returning an empty array
    );
  }
}
